/* ### Font Awesome Icons ###*/
/* ### Navigational Icons */
/* ### Other Icons */
/* XS Mobile (max width) */
/* Mobile (min width) */
/* Mobile (max width) */
/* Tablets (min width) */
/* Tablets (max width) */
/* Small Desktop  (min width) */
/* Small Desktop (max width) */
/* Large Desktop (min width) - THIS IS THE DEFAULT STYLES */
/* Large Desktop Breakpoint where we need to apply global innerWrapper Padding */
/*Set maximum viewable area of the site */
/* ### Font Awesome Icons ###*/
/* ### Navigational Icons */
/* ### Other Icons */
/* XS Mobile (max width) */
/* Mobile (min width) */
/* Mobile (max width) */
/* Tablets (min width) */
/* Tablets (max width) */
/* Small Desktop  (min width) */
/* Small Desktop (max width) */
/* Large Desktop (min width) - THIS IS THE DEFAULT STYLES */
/* Large Desktop Breakpoint where we need to apply global innerWrapper Padding */
/*Set maximum viewable area of the site */
/*@if(type-of($Columns) == list) {
    // loop through values and assign to columns
    @each $col in $Columns {
    }
}*/
.newGrid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.newGrid > .col {
  /*flex: 1 0 auto;*/
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  /*order: 0;*/
  min-width: 0;
  max-width: 100%;
  padding: 0 0.5rem;
  vertical-align: top;
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}

/* ### REM FALLBACK ### */
/*---------------   
--Mixin: overlay
----------------- */
/* Old Font Size Function
@mixin font-size($sizeValue, $line: 1.5){
  font-size: ($sizeValue) + px;
  line-height: ($line * $sizeValue) + px;
  font-size: ($sizeValue / 10) *.625 + rem;
  line-height: (($line * $sizeValue) / 10) *.625 + rem;
}
*/
/*
$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 24px;

@include fluid-type($min_width, $max_width, $min_font, $max_font);

*/
/* ### Font Awesome Icons ###*/
/* ### Navigational Icons */
/* ### Other Icons */
/* XS Mobile (max width) */
/* Mobile (min width) */
/* Mobile (max width) */
/* Tablets (min width) */
/* Tablets (max width) */
/* Small Desktop  (min width) */
/* Small Desktop (max width) */
/* Large Desktop (min width) - THIS IS THE DEFAULT STYLES */
/* Large Desktop Breakpoint where we need to apply global innerWrapper Padding */
/*Set maximum viewable area of the site */
/*@if(type-of($Columns) == list) {
    // loop through values and assign to columns
    @each $col in $Columns {
    }
}*/
.newGrid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.newGrid > .col {
  /*flex: 1 0 auto;*/
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  /*order: 0;*/
  min-width: 0;
  max-width: 100%;
  padding: 0 0.5rem;
  vertical-align: top;
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}

/* ### REM FALLBACK ### */
/*---------------   
--Mixin: overlay
----------------- */
/* Old Font Size Function
@mixin font-size($sizeValue, $line: 1.5){
  font-size: ($sizeValue) + px;
  line-height: ($line * $sizeValue) + px;
  font-size: ($sizeValue / 10) *.625 + rem;
  line-height: (($line * $sizeValue) / 10) *.625 + rem;
}
*/
/*
$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 24px;

@include fluid-type($min_width, $max_width, $min_font, $max_font);

*/
/* Force text to wrap with hyphen if to long (example long email) */
/*
    Placeholder usage:

    .className {
        @extend %placeHoldername;
    }
*/
@media print {
  a[href]:after {
    content: none !important;
  }

  /* Hide these elements */
  .topHeader {
    display: none !important;
  }

  .mobile,
.topHeader,
header nav,
header .search,
footer,
video, audio, object, embed {
    display: none !important;
    height: 0 !important;
  }

  header .header.stuck {
    position: relative !important;
  }

  img {
    max-width: 100%;
  }

  @page {
    margin: 0.5cm;
  }
  body {
    line-height: 1.3em;
  }

  h1 {
    font-size: 21pt;
  }

  h2 {
    font-size: 15pt;
    margin-top: 25px;
  }

  h3 {
    font-size: 13pt;
    margin-top: 20px;
  }

  p a {
    color: black;
    word-wrap: break-word;
  }
  p a:after {
    content: " " attr(href) ")";
  }
  p a[href^="#"]:after {
    display: none;
  }
  p a[href^="http://"]:after, p a a[href^="https://"]:after {
    content: " (" attr(href) ")";
  }

  .pageBanner {
    min-height: 0;
  }
  .pageBanner .bannerImage {
    display: none;
  }

  .resources .innerContent .itemImage {
    display: none;
  }

  .filteredContent .filtersContainer, .filteredContent #loadingIndicator, .filteredContent #loadMoreContainer {
    display: none !important;
  }

  .viewMoreOuter {
    display: none;
  }

  .teammembers .teammember .bgImgWrapper {
    display: none;
  }

  .teammember .bannerGrid {
    height: auto;
  }
  .teammember .bannerGrid .bioTopContent.flexCol {
    color: #000;
    background-color: #fff;
  }
  .teammember .bannerGrid .bioTopContent.flexCol .bioContact {
    text-align: left;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 100%;
  }
  .teammember .bannerGrid .bioTopContent.flexCol .bioContact h1 {
    color: #000;
  }
  .teammember .bannerGrid .bioTopContent.flexCol .bioContact a:link, .teammember .bannerGrid .bioTopContent.flexCol .bioContact a:visited, .teammember .bannerGrid .bioTopContent.flexCol .bioContact a:active {
    color: #000;
  }
  .teammember .bannerGrid .bioTopContent.flexCol .bioContact .offices ul li {
    border-right: 1px solid #000;
  }
  .teammember .bannerGrid .bioTopContent.flexCol .bioContact .offices ul li a {
    color: #000;
  }
  .teammember .bgImgWrapper {
    display: none;
    height: 0;
  }

  .expertise.detailView .pageBanner, .sector.detailView .pageBanner, .solution.detailView .pageBanner {
    min-height: 0;
    padding-top: 2rem;
  }
  .expertise.detailView .pageBanner .bannerImage, .sector.detailView .pageBanner .bannerImage, .solution.detailView .pageBanner .bannerImage {
    display: none;
  }
  .expertise.detailView .pageBanner .innerContainer, .sector.detailView .pageBanner .innerContainer, .solution.detailView .pageBanner .innerContainer {
    z-index: 1;
  }
  .expertise.detailView .pageBanner .innerContainer .bannerTitle h1, .sector.detailView .pageBanner .innerContainer .bannerTitle h1, .solution.detailView .pageBanner .innerContainer .bannerTitle h1 {
    color: #000;
  }
}