@charset "UTF-8";
/* ### Font Awesome Icons ###*/
/* ### Navigational Icons */
/* ### Other Icons */
/* XS Mobile (max width) */
/* Mobile (min width) */
/* Mobile (max width) */
/* Tablets (min width) */
/* Tablets (max width) */
/* Small Desktop  (min width) */
/* Small Desktop (max width) */
/* Large Desktop (min width) - THIS IS THE DEFAULT STYLES */
/* Large Desktop Breakpoint where we need to apply global innerWrapper Padding */
/*Set maximum viewable area of the site */
/* ### Font Awesome Icons ###*/
/* ### Navigational Icons */
/* ### Other Icons */
/* XS Mobile (max width) */
/* Mobile (min width) */
/* Mobile (max width) */
/* Tablets (min width) */
/* Tablets (max width) */
/* Small Desktop  (min width) */
/* Small Desktop (max width) */
/* Large Desktop (min width) - THIS IS THE DEFAULT STYLES */
/* Large Desktop Breakpoint where we need to apply global innerWrapper Padding */
/*Set maximum viewable area of the site */
/*@if(type-of($Columns) == list) {
    // loop through values and assign to columns
    @each $col in $Columns {
    }
}*/
.newGrid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.newGrid > .col {
  /*flex: 1 0 auto;*/
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  /*order: 0;*/
  min-width: 0;
  max-width: 100%;
  padding: 0 0.5rem;
  vertical-align: top;
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}

/* ### REM FALLBACK ### */
/*---------------   
--Mixin: overlay
----------------- */
/* Old Font Size Function
@mixin font-size($sizeValue, $line: 1.5){
  font-size: ($sizeValue) + px;
  line-height: ($line * $sizeValue) + px;
  font-size: ($sizeValue / 10) *.625 + rem;
  line-height: (($line * $sizeValue) / 10) *.625 + rem;
}
*/
/*
$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 24px;

@include fluid-type($min_width, $max_width, $min_font, $max_font);

*/
/* ### Font Awesome Icons ###*/
/* ### Navigational Icons */
/* ### Other Icons */
/* XS Mobile (max width) */
/* Mobile (min width) */
/* Mobile (max width) */
/* Tablets (min width) */
/* Tablets (max width) */
/* Small Desktop  (min width) */
/* Small Desktop (max width) */
/* Large Desktop (min width) - THIS IS THE DEFAULT STYLES */
/* Large Desktop Breakpoint where we need to apply global innerWrapper Padding */
/*Set maximum viewable area of the site */
/*@if(type-of($Columns) == list) {
    // loop through values and assign to columns
    @each $col in $Columns {
    }
}*/
.newGrid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.newGrid > .col {
  /*flex: 1 0 auto;*/
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  /*order: 0;*/
  min-width: 0;
  max-width: 100%;
  padding: 0 0.5rem;
  vertical-align: top;
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}

/* ### REM FALLBACK ### */
/*---------------   
--Mixin: overlay
----------------- */
/* Old Font Size Function
@mixin font-size($sizeValue, $line: 1.5){
  font-size: ($sizeValue) + px;
  line-height: ($line * $sizeValue) + px;
  font-size: ($sizeValue / 10) *.625 + rem;
  line-height: (($line * $sizeValue) / 10) *.625 + rem;
}
*/
/*
$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 24px;

@include fluid-type($min_width, $max_width, $min_font, $max_font);

*/
.filters input[type=checkbox] + label:before, .sf-Long-text ul:not(.reset) > li ul > li:before,
.cmsContent ul:not(.reset) > li ul > li:before, .filters input[type=checkbox] + label:after, .sectors > .grid .col h3:before, .expertises > .grid .col h3:before, .sf-Long-text ul:not(.reset) > li:before,
.cmsContent ul:not(.reset) > li:before, header .header .topHeaderGrid .search button:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
}

.filters input[type=checkbox] + label:after, .sectors > .grid .col h3:before, .expertises > .grid .col h3:before, .sf-Long-text ul:not(.reset) > li:before,
.cmsContent ul:not(.reset) > li:before, header .header .topHeaderGrid .search button:after {
  font-weight: 700;
}

.filters input[type=checkbox] + label:before, .sf-Long-text ul:not(.reset) > li ul > li:before,
.cmsContent ul:not(.reset) > li ul > li:before {
  font-weight: 400;
}

span.pseudoH1, .pageBanner .bannerTitle h1 {
  position: relative;
}
span.pseudoH1:after, .pageBanner .bannerTitle h1:after {
  content: "";
  height: 35px;
  background-color: #E4EAED;
  position: absolute;
  bottom: -35px;
  width: 100vw;
  margin-left: -90vw;
}
span.tertiary.pseudoH1:before, .pageBanner .bannerTitle h1.tertiary:before {
  background-color: #003488;
}

a.button, .mixitup-page-list button, .mixContainer button, .filters button, .stickyPhone .stickyPhoneInner a, .button, .pageWrapper button,
.pageWrapper input[type=file],
.pageWrapper input[type=reset],
.pageWrapper input[type=submit],
.pageWrapper input[type=button],
footer button,
footer input[type=file],
footer input[type=reset],
footer input[type=submit],
footer input[type=button],
.popup_wrapper button,
.popup_wrapper input[type=file],
.popup_wrapper input[type=reset],
.popup_wrapper input[type=submit],
.popup_wrapper input[type=button] {
  border-radius: 0;
  background-color: #0047BB;
  padding: 0.5em 1em;
  color: #fff;
  text-transform: uppercase;
  display: inline-block;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: 0px solid black;
  text-decoration: none;
  font-family: "canada-type-gibson", Arial, sans-serif;
  font-weight: 700;
  cursor: pointer;
}
.mixitup-page-list button:hover, .mixContainer button:hover, .filters button:hover, .stickyPhone .stickyPhoneInner a:hover, .button:hover, .pageWrapper button:hover,
.pageWrapper input[type=file]:hover,
.pageWrapper input[type=reset]:hover,
.pageWrapper input[type=submit]:hover,
.pageWrapper input[type=button]:hover,
footer button:hover,
footer input[type=file]:hover,
footer input[type=reset]:hover,
footer input[type=submit]:hover,
footer input[type=button]:hover,
.popup_wrapper button:hover,
.popup_wrapper input[type=file]:hover,
.popup_wrapper input[type=reset]:hover,
.popup_wrapper input[type=submit]:hover,
.popup_wrapper input[type=button]:hover {
  color: #ffffff;
  background-color: black;
  text-decoration: none !important;
}
.mixitup-page-list button.reversed, .mixContainer button.reversed, .filters button.reversed, .stickyPhone .stickyPhoneInner a.reversed, .reversed.button, .pageWrapper button.reversed,
.pageWrapper input.reversed[type=file],
.pageWrapper input.reversed[type=reset],
.pageWrapper input.reversed[type=submit],
.pageWrapper input.reversed[type=button],
footer button.reversed,
footer input.reversed[type=file],
footer input.reversed[type=reset],
footer input.reversed[type=submit],
footer input.reversed[type=button],
.popup_wrapper button.reversed,
.popup_wrapper input.reversed[type=file],
.popup_wrapper input.reversed[type=reset],
.popup_wrapper input.reversed[type=submit],
.popup_wrapper input.reversed[type=button] {
  background-color: none;
  color: #0047BB !important;
}
.mixitup-page-list button.reversed:hover, .mixContainer button.reversed:hover, .filters button.reversed:hover, .stickyPhone .stickyPhoneInner a.reversed:hover, .reversed.button:hover, .pageWrapper button.reversed:hover,
.pageWrapper input.reversed[type=file]:hover,
.pageWrapper input.reversed[type=reset]:hover,
.pageWrapper input.reversed[type=submit]:hover,
.pageWrapper input.reversed[type=button]:hover,
footer button.reversed:hover,
footer input.reversed[type=file]:hover,
footer input.reversed[type=reset]:hover,
footer input.reversed[type=submit]:hover,
footer input.reversed[type=button]:hover,
.popup_wrapper button.reversed:hover,
.popup_wrapper input.reversed[type=file]:hover,
.popup_wrapper input.reversed[type=reset]:hover,
.popup_wrapper input.reversed[type=submit]:hover,
.popup_wrapper input.reversed[type=button]:hover {
  background-color: black;
  color: white !important;
}
.mixitup-page-list button.reset, .mixContainer button.reset, .filters button.reset, .stickyPhone .stickyPhoneInner a.reset, .reset.button, .pageWrapper button.reset,
.pageWrapper input.reset[type=file],
.pageWrapper input.reset[type=reset],
.pageWrapper input.reset[type=submit],
.pageWrapper input.reset[type=button],
footer button.reset,
footer input.reset[type=file],
footer input.reset[type=reset],
footer input.reset[type=submit],
footer input.reset[type=button],
.popup_wrapper button.reset,
.popup_wrapper input.reset[type=file],
.popup_wrapper input.reset[type=reset],
.popup_wrapper input.reset[type=submit],
.popup_wrapper input.reset[type=button] {
  background-color: white;
  color: black;
  border: 0;
}
.mixitup-page-list button.reset:hover, .mixContainer button.reset:hover, .filters button.reset:hover, .stickyPhone .stickyPhoneInner a.reset:hover, .reset.button:hover, .pageWrapper button.reset:hover,
.pageWrapper input.reset[type=file]:hover,
.pageWrapper input.reset[type=reset]:hover,
.pageWrapper input.reset[type=submit]:hover,
.pageWrapper input.reset[type=button]:hover,
footer button.reset:hover,
footer input.reset[type=file]:hover,
footer input.reset[type=reset]:hover,
footer input.reset[type=submit]:hover,
footer input.reset[type=button]:hover,
.popup_wrapper button.reset:hover,
.popup_wrapper input.reset[type=file]:hover,
.popup_wrapper input.reset[type=reset]:hover,
.popup_wrapper input.reset[type=submit]:hover,
.popup_wrapper input.reset[type=button]:hover {
  color: #333232 !important;
}
.mixitup-page-list button.submit, .mixContainer button.submit, .filters button.submit, .stickyPhone .stickyPhoneInner a.submit, .submit.button, .pageWrapper button.submit,
.pageWrapper input.submit[type=file],
.pageWrapper input.submit[type=reset],
.pageWrapper input.submit[type=submit],
.pageWrapper input.submit[type=button],
footer button.submit,
footer input.submit[type=file],
footer input.submit[type=reset],
footer input.submit[type=submit],
footer input.submit[type=button],
.popup_wrapper button.submit,
.popup_wrapper input.submit[type=file],
.popup_wrapper input.submit[type=reset],
.popup_wrapper input.submit[type=submit],
.popup_wrapper input.submit[type=button] {
  text-align: right;
}
.mixitup-page-list button.primary, .mixContainer button.primary, .filters button.primary, .stickyPhone .stickyPhoneInner a.primary, .primary.button, .pageWrapper button.primary,
.pageWrapper input.primary[type=file],
.pageWrapper input.primary[type=reset],
.pageWrapper input.primary[type=submit],
.pageWrapper input.primary[type=button],
footer button.primary,
footer input.primary[type=file],
footer input.primary[type=reset],
footer input.primary[type=submit],
footer input.primary[type=button],
.popup_wrapper button.primary,
.popup_wrapper input.primary[type=file],
.popup_wrapper input.primary[type=reset],
.popup_wrapper input.primary[type=submit],
.popup_wrapper input.primary[type=button] {
  background-color: black;
}

.resources .innerContent .itemContent a.readMore {
  color: #0047BB;
  font-weight: 600;
  display: block;
  font-size: 1rem;
  text-transform: uppercase;
  text-decoration: none;
  padding: 1rem 0.2rem 0.4rem;
  border-bottom: 2px solid #0047BB;
}
.resources .innerContent .itemContent a.readMore:hover, .resources .innerContent .itemContent a.readMore:active, .resources .innerContent .itemContent a.readMore:focus {
  color: black;
}
.resources .innerContent .itemContent a.readMore:after {
  content: "";
  margin: auto 0.5em;
  font-family: "Font Awesome 5 Pro";
}

.sf-list--expandable .sf-list-item a, .awardrankings .awardTop, .rankings .awardTop {
  -webkit-box-shadow: 3px 5px 10px 1px rgba(51, 50, 50, 0.6);
  box-shadow: 3px 5px 10px 1px rgba(51, 50, 50, 0.6);
  padding: 0.5em;
}

/* Force text to wrap with hyphen if to long (example long email) */
a[href^=mailto] {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  white-space: unset;
}

.pageBanner .bannerImage .slick-slider .slide .bannerImage {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.homeSlideshow .slide {
  position: relative;
}
.homeSlideshow .slide .coverImg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
}
.homeSlideshow .slide .coverImg img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  max-height: none;
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

/*
    Placeholder usage:

    .className {
        @extend %placeHoldername;
    }
*/
article.projectCard .bgImgWrapper, .factgroups .bgImgWrapper .bgImg, .factgroups .bgImgWrapper, .featuredcontentgroups div.item .contentWrapper:before, .featuredcontentgroups div.item .coverImg, .awardrankings .awardTop:before, .rankings .awardTop:before, .expertises.accordionUL ul.level1 li.hasChildren.active:before, .sectors.accordionUL ul.level1 li.hasChildren.active:before, .teammembers .teammember .bgImgWrapper a span.bgImg, div.bgImgWrapper a span.bgImg, .pageBanner .bannerImage .bgImg, .pageBanner .bannerImage {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  position: absolute;
}

/* ### Font Awesome Icons ###*/
/* ### Navigational Icons */
/* ### Other Icons */
/* XS Mobile (max width) */
/* Mobile (min width) */
/* Mobile (max width) */
/* Tablets (min width) */
/* Tablets (max width) */
/* Small Desktop  (min width) */
/* Small Desktop (max width) */
/* Large Desktop (min width) - THIS IS THE DEFAULT STYLES */
/* Large Desktop Breakpoint where we need to apply global innerWrapper Padding */
/*Set maximum viewable area of the site */
/* ### Font Awesome Icons ###*/
/* ### Navigational Icons */
/* ### Other Icons */
/* XS Mobile (max width) */
/* Mobile (min width) */
/* Mobile (max width) */
/* Tablets (min width) */
/* Tablets (max width) */
/* Small Desktop  (min width) */
/* Small Desktop (max width) */
/* Large Desktop (min width) - THIS IS THE DEFAULT STYLES */
/* Large Desktop Breakpoint where we need to apply global innerWrapper Padding */
/*Set maximum viewable area of the site */
/*@if(type-of($Columns) == list) {
    // loop through values and assign to columns
    @each $col in $Columns {
    }
}*/
.newGrid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.newGrid > .col {
  /*flex: 1 0 auto;*/
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  /*order: 0;*/
  min-width: 0;
  max-width: 100%;
  padding: 0 0.5rem;
  vertical-align: top;
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}

/* ### REM FALLBACK ### */
/*---------------   
--Mixin: overlay
----------------- */
/* Old Font Size Function
@mixin font-size($sizeValue, $line: 1.5){
  font-size: ($sizeValue) + px;
  line-height: ($line * $sizeValue) + px;
  font-size: ($sizeValue / 10) *.625 + rem;
  line-height: (($line * $sizeValue) / 10) *.625 + rem;
}
*/
/*
$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 24px;

@include fluid-type($min_width, $max_width, $min_font, $max_font);

*/
/* ### Font Awesome Icons ###*/
/* ### Navigational Icons */
/* ### Other Icons */
/* XS Mobile (max width) */
/* Mobile (min width) */
/* Mobile (max width) */
/* Tablets (min width) */
/* Tablets (max width) */
/* Small Desktop  (min width) */
/* Small Desktop (max width) */
/* Large Desktop (min width) - THIS IS THE DEFAULT STYLES */
/* Large Desktop Breakpoint where we need to apply global innerWrapper Padding */
/*Set maximum viewable area of the site */
/*@if(type-of($Columns) == list) {
    // loop through values and assign to columns
    @each $col in $Columns {
    }
}*/
.newGrid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.newGrid > .col {
  /*flex: 1 0 auto;*/
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  /*order: 0;*/
  min-width: 0;
  max-width: 100%;
  padding: 0 0.5rem;
  vertical-align: top;
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}

/* ### REM FALLBACK ### */
/*---------------   
--Mixin: overlay
----------------- */
/* Old Font Size Function
@mixin font-size($sizeValue, $line: 1.5){
  font-size: ($sizeValue) + px;
  line-height: ($line * $sizeValue) + px;
  font-size: ($sizeValue / 10) *.625 + rem;
  line-height: (($line * $sizeValue) / 10) *.625 + rem;
}
*/
/*
$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 24px;

@include fluid-type($min_width, $max_width, $min_font, $max_font);

*/
.filters input[type=checkbox] + label:after, .sectors > .grid .col h3:before, .expertises > .grid .col h3:before, .sf-Long-text ul:not(.reset) > li:before,
.cmsContent ul:not(.reset) > li:before, header .header .topHeaderGrid .search button:after, .filters input[type=checkbox] + label:before, .sf-Long-text ul:not(.reset) > li ul > li:before,
.cmsContent ul:not(.reset) > li ul > li:before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
}

.filters input[type=checkbox] + label:after, .sectors > .grid .col h3:before, .expertises > .grid .col h3:before, .sf-Long-text ul:not(.reset) > li:before,
.cmsContent ul:not(.reset) > li:before, header .header .topHeaderGrid .search button:after {
  font-weight: 700;
}

.filters input[type=checkbox] + label:before, .sf-Long-text ul:not(.reset) > li ul > li:before,
.cmsContent ul:not(.reset) > li ul > li:before {
  font-weight: 400;
}

span.pseudoH1, .pageBanner .bannerTitle h1 {
  position: relative;
}
span.pseudoH1:after, .pageBanner .bannerTitle h1:after {
  content: "";
  height: 35px;
  background-color: #E4EAED;
  position: absolute;
  bottom: -35px;
  width: 100vw;
  margin-left: -90vw;
}
span.tertiary.pseudoH1:before, .pageBanner .bannerTitle h1.tertiary:before {
  background-color: #003488;
}

a.button, .mixitup-page-list button, .mixContainer button, .filters button, .stickyPhone .stickyPhoneInner a, .button, .pageWrapper button,
.pageWrapper input[type=file],
.pageWrapper input[type=reset],
.pageWrapper input[type=submit],
.pageWrapper input[type=button],
footer button,
footer input[type=file],
footer input[type=reset],
footer input[type=submit],
footer input[type=button],
.popup_wrapper button,
.popup_wrapper input[type=file],
.popup_wrapper input[type=reset],
.popup_wrapper input[type=submit],
.popup_wrapper input[type=button] {
  border-radius: 0;
  background-color: #0047BB;
  padding: 0.5em 1em;
  color: #fff;
  text-transform: uppercase;
  display: inline-block;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: 0px solid black;
  text-decoration: none;
  font-family: "canada-type-gibson", Arial, sans-serif;
  font-weight: 700;
  cursor: pointer;
}
.mixitup-page-list button:hover, .mixContainer button:hover, .filters button:hover, .stickyPhone .stickyPhoneInner a:hover, .button:hover, .pageWrapper button:hover,
.pageWrapper input[type=file]:hover,
.pageWrapper input[type=reset]:hover,
.pageWrapper input[type=submit]:hover,
.pageWrapper input[type=button]:hover,
footer button:hover,
footer input[type=file]:hover,
footer input[type=reset]:hover,
footer input[type=submit]:hover,
footer input[type=button]:hover,
.popup_wrapper button:hover,
.popup_wrapper input[type=file]:hover,
.popup_wrapper input[type=reset]:hover,
.popup_wrapper input[type=submit]:hover,
.popup_wrapper input[type=button]:hover {
  color: #ffffff;
  background-color: black;
  text-decoration: none !important;
}
.mixitup-page-list button.reversed, .mixContainer button.reversed, .filters button.reversed, .stickyPhone .stickyPhoneInner a.reversed, .reversed.button, .pageWrapper button.reversed,
.pageWrapper input.reversed[type=file],
.pageWrapper input.reversed[type=reset],
.pageWrapper input.reversed[type=submit],
.pageWrapper input.reversed[type=button],
footer button.reversed,
footer input.reversed[type=file],
footer input.reversed[type=reset],
footer input.reversed[type=submit],
footer input.reversed[type=button],
.popup_wrapper button.reversed,
.popup_wrapper input.reversed[type=file],
.popup_wrapper input.reversed[type=reset],
.popup_wrapper input.reversed[type=submit],
.popup_wrapper input.reversed[type=button] {
  background-color: none;
  color: #0047BB !important;
}
.mixitup-page-list button.reversed:hover, .mixContainer button.reversed:hover, .filters button.reversed:hover, .stickyPhone .stickyPhoneInner a.reversed:hover, .reversed.button:hover, .pageWrapper button.reversed:hover,
.pageWrapper input.reversed[type=file]:hover,
.pageWrapper input.reversed[type=reset]:hover,
.pageWrapper input.reversed[type=submit]:hover,
.pageWrapper input.reversed[type=button]:hover,
footer button.reversed:hover,
footer input.reversed[type=file]:hover,
footer input.reversed[type=reset]:hover,
footer input.reversed[type=submit]:hover,
footer input.reversed[type=button]:hover,
.popup_wrapper button.reversed:hover,
.popup_wrapper input.reversed[type=file]:hover,
.popup_wrapper input.reversed[type=reset]:hover,
.popup_wrapper input.reversed[type=submit]:hover,
.popup_wrapper input.reversed[type=button]:hover {
  background-color: black;
  color: white !important;
}
.mixitup-page-list button.reset, .mixContainer button.reset, .filters button.reset, .stickyPhone .stickyPhoneInner a.reset, .reset.button, .pageWrapper button.reset,
.pageWrapper input.reset[type=file],
.pageWrapper input.reset[type=reset],
.pageWrapper input.reset[type=submit],
.pageWrapper input.reset[type=button],
footer button.reset,
footer input.reset[type=file],
footer input.reset[type=reset],
footer input.reset[type=submit],
footer input.reset[type=button],
.popup_wrapper button.reset,
.popup_wrapper input.reset[type=file],
.popup_wrapper input.reset[type=reset],
.popup_wrapper input.reset[type=submit],
.popup_wrapper input.reset[type=button] {
  background-color: white;
  color: black;
  border: 0;
}
.mixitup-page-list button.reset:hover, .mixContainer button.reset:hover, .filters button.reset:hover, .stickyPhone .stickyPhoneInner a.reset:hover, .reset.button:hover, .pageWrapper button.reset:hover,
.pageWrapper input.reset[type=file]:hover,
.pageWrapper input.reset[type=reset]:hover,
.pageWrapper input.reset[type=submit]:hover,
.pageWrapper input.reset[type=button]:hover,
footer button.reset:hover,
footer input.reset[type=file]:hover,
footer input.reset[type=reset]:hover,
footer input.reset[type=submit]:hover,
footer input.reset[type=button]:hover,
.popup_wrapper button.reset:hover,
.popup_wrapper input.reset[type=file]:hover,
.popup_wrapper input.reset[type=reset]:hover,
.popup_wrapper input.reset[type=submit]:hover,
.popup_wrapper input.reset[type=button]:hover {
  color: #333232 !important;
}
.mixitup-page-list button.submit, .mixContainer button.submit, .filters button.submit, .stickyPhone .stickyPhoneInner a.submit, .submit.button, .pageWrapper button.submit,
.pageWrapper input.submit[type=file],
.pageWrapper input.submit[type=reset],
.pageWrapper input.submit[type=submit],
.pageWrapper input.submit[type=button],
footer button.submit,
footer input.submit[type=file],
footer input.submit[type=reset],
footer input.submit[type=submit],
footer input.submit[type=button],
.popup_wrapper button.submit,
.popup_wrapper input.submit[type=file],
.popup_wrapper input.submit[type=reset],
.popup_wrapper input.submit[type=submit],
.popup_wrapper input.submit[type=button] {
  text-align: right;
}
.mixitup-page-list button.primary, .mixContainer button.primary, .filters button.primary, .stickyPhone .stickyPhoneInner a.primary, .primary.button, .pageWrapper button.primary,
.pageWrapper input.primary[type=file],
.pageWrapper input.primary[type=reset],
.pageWrapper input.primary[type=submit],
.pageWrapper input.primary[type=button],
footer button.primary,
footer input.primary[type=file],
footer input.primary[type=reset],
footer input.primary[type=submit],
footer input.primary[type=button],
.popup_wrapper button.primary,
.popup_wrapper input.primary[type=file],
.popup_wrapper input.primary[type=reset],
.popup_wrapper input.primary[type=submit],
.popup_wrapper input.primary[type=button] {
  background-color: black;
}

.resources .innerContent .itemContent a.readMore {
  color: #0047BB;
  font-weight: 600;
  display: block;
  font-size: 1rem;
  text-transform: uppercase;
  text-decoration: none;
  padding: 1rem 0.2rem 0.4rem;
  border-bottom: 2px solid #0047BB;
}
.resources .innerContent .itemContent a.readMore:hover, .resources .innerContent .itemContent a.readMore:active, .resources .innerContent .itemContent a.readMore:focus {
  color: black;
}
.resources .innerContent .itemContent a.readMore:after {
  content: "";
  margin: auto 0.5em;
  font-family: "Font Awesome 5 Pro";
}

.sf-list--expandable .sf-list-item a, .awardrankings .awardTop, .rankings .awardTop {
  -webkit-box-shadow: 3px 5px 10px 1px rgba(51, 50, 50, 0.6);
  box-shadow: 3px 5px 10px 1px rgba(51, 50, 50, 0.6);
  padding: 0.5em;
}

/* Force text to wrap with hyphen if to long (example long email) */
a[href^=mailto] {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  white-space: unset;
}

.pageBanner .bannerImage .slick-slider .slide .bannerImage {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.homeSlideshow .slide {
  position: relative;
}
.homeSlideshow .slide .coverImg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
}
.homeSlideshow .slide .coverImg img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  max-height: none;
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

/*
    Placeholder usage:

    .className {
        @extend %placeHoldername;
    }
*/
article.projectCard .bgImgWrapper, .factgroups .bgImgWrapper .bgImg, .factgroups .bgImgWrapper, .featuredcontentgroups div.item .contentWrapper:before, .featuredcontentgroups div.item .coverImg, .awardrankings .awardTop:before, .rankings .awardTop:before, .expertises.accordionUL ul.level1 li.hasChildren.active:before, .sectors.accordionUL ul.level1 li.hasChildren.active:before, .teammembers .teammember .bgImgWrapper a span.bgImg, div.bgImgWrapper a span.bgImg, .pageBanner .bannerImage .bgImg, .pageBanner .bannerImage {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  position: absolute;
}

/*$base-unit: 12px;*/
.grid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  margin: 0 -1rem 0 -1rem;
}
.grid.grid-nogutter {
  margin: 0;
}
.grid.grid-nogutter > .col {
  /*padding: 0;*/
  margin: 0;
}
.grid.grid-nowrap {
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
}
.grid.grid-doublegutter > .col {
  padding: 0 4rem 0 4rem;
}
.featureFirst .grid .col:first-child, .grid.featureFirst .col:first-child {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 1) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 1) * 100% - 1rem);
          flex-basis: calc((1 / 1) * 100% - 1rem);
}
.grid:not(.col) {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.grid > .col {
  margin: 0 1rem 0 1rem;
}
@media (max-width: 1299px) and (max-width: 1299px) {
  .grid.grid-xl {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .grid.grid-xl > .col {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
    max-width: initial !important;
    -webkit-flex-basis: initial !important;
        -ms-flex-preferred-size: initial !important;
            flex-basis: initial !important;
  }
}
@media (max-width: 1024px) and (max-width: 1024px) {
  .grid.grid-lg {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .grid.grid-lg > .col {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
    max-width: initial !important;
    -webkit-flex-basis: initial !important;
        -ms-flex-preferred-size: initial !important;
            flex-basis: initial !important;
  }
}
@media (max-width: 767px) and (max-width: 767px) {
  .grid.grid-md {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .grid.grid-md > .col {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
    max-width: initial !important;
    -webkit-flex-basis: initial !important;
        -ms-flex-preferred-size: initial !important;
            flex-basis: initial !important;
  }
}
@media (max-width: 600px) and (max-width: 600px) {
  .grid.grid-sm {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .grid.grid-sm > .col {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
    max-width: initial !important;
    -webkit-flex-basis: initial !important;
        -ms-flex-preferred-size: initial !important;
            flex-basis: initial !important;
  }
}

.grid[data-gutter-width] {
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
}
.sfPageEditor .grid[data-gutter-width] > .col {
  -webkit-box-flex: 1 !important;
  -webkit-flex-grow: 1 !important;
      -ms-flex-positive: 1 !important;
          flex-grow: 1 !important;
  -webkit-flex-shrink: 0 !important;
      -ms-flex-negative: 0 !important;
          flex-shrink: 0 !important;
  -webkit-flex-basis: 0 !important;
      -ms-flex-preferred-size: 0 !important;
          flex-basis: 0 !important;
}
.grid[data-gutter-width]:not([data-media-breakpoint]) {
  /*@media (max-width: $M_Max) {
      @extend %colStackedStyles;
  }*/
}
@media (max-width: 1299px) and (max-width: 1299px) {
  .grid[data-gutter-width][data-media-breakpoint="1299px"] {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .grid[data-gutter-width][data-media-breakpoint="1299px"] > .col {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
    max-width: initial !important;
    -webkit-flex-basis: initial !important;
        -ms-flex-preferred-size: initial !important;
            flex-basis: initial !important;
  }
}
@media (max-width: 1024px) and (max-width: 1024px) {
  .grid[data-gutter-width][data-media-breakpoint="1024px"] {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .grid[data-gutter-width][data-media-breakpoint="1024px"] > .col {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
    max-width: initial !important;
    -webkit-flex-basis: initial !important;
        -ms-flex-preferred-size: initial !important;
            flex-basis: initial !important;
  }
}
@media (max-width: 767px) and (max-width: 767px) {
  .grid[data-gutter-width][data-media-breakpoint="767px"] {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .grid[data-gutter-width][data-media-breakpoint="767px"] > .col {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
    max-width: initial !important;
    -webkit-flex-basis: initial !important;
        -ms-flex-preferred-size: initial !important;
            flex-basis: initial !important;
  }
}
@media (max-width: 600px) and (max-width: 600px) {
  .grid[data-gutter-width][data-media-breakpoint="500px"] {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .grid[data-gutter-width][data-media-breakpoint="500px"] > .col {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
    max-width: initial !important;
    -webkit-flex-basis: initial !important;
        -ms-flex-preferred-size: initial !important;
            flex-basis: initial !important;
  }
}

.col {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  max-width: 100%;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
html.k-ie.k-ie11 .col > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}

/*.col-align-top {
    align-self: flex-start;
}

.col-align-bottom {
    align-self: flex-end;
}

.col-align-middle {
    align-self: center;
}*/
.grid-start,
.col-top {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.grid-end,
.col-bottom {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.grid-center,
.col-middle {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.grid-evenly,
.col-evenly {
  -webkit-box-pack: space-evenly;
  -webkit-justify-content: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.grid-around,
.col-around {
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}

.grid-between,
.col-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.col-align-start,
.grid-align-start {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.col-align-end,
.grid-align-end {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.col-align-center,
.grid-align-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.col-align-stretch,
.grid-align-stretch {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

.col-align-baseline,
.grid-align-baseline {
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}

.col-first {
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
      -ms-flex-order: -1;
          order: -1;
}

.col-last {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
      -ms-flex-order: 1;
          order: 1;
}

.col-fixed {
  -webkit-box-flex: initial;
  -webkit-flex: initial;
      -ms-flex: initial;
          flex: initial;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
}

.col-auto-width {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
}

.col-grow-2 {
  -webkit-box-flex: 2;
  -webkit-flex-grow: 2;
      -ms-flex-positive: 2;
          flex-grow: 2;
}

.col-grow-3 {
  -webkit-box-flex: 3;
  -webkit-flex-grow: 3;
      -ms-flex-positive: 3;
          flex-grow: 3;
}

.col-grow-4 {
  -webkit-box-flex: 4;
  -webkit-flex-grow: 4;
      -ms-flex-positive: 4;
          flex-grow: 4;
}

.col-grow-5 {
  -webkit-box-flex: 5;
  -webkit-flex-grow: 5;
      -ms-flex-positive: 5;
          flex-grow: 5;
}

.col-grow-6 {
  -webkit-box-flex: 6;
  -webkit-flex-grow: 6;
      -ms-flex-positive: 6;
          flex-grow: 6;
}

.col-grow-7 {
  -webkit-box-flex: 7;
  -webkit-flex-grow: 7;
      -ms-flex-positive: 7;
          flex-grow: 7;
}

.col-grow-8 {
  -webkit-box-flex: 8;
  -webkit-flex-grow: 8;
      -ms-flex-positive: 8;
          flex-grow: 8;
}

.col-grow-9 {
  -webkit-box-flex: 9;
  -webkit-flex-grow: 9;
      -ms-flex-positive: 9;
          flex-grow: 9;
}

.col-grow-10 {
  -webkit-box-flex: 10;
  -webkit-flex-grow: 10;
      -ms-flex-positive: 10;
          flex-grow: 10;
}

.col-grow-11 {
  -webkit-box-flex: 11;
  -webkit-flex-grow: 11;
      -ms-flex-positive: 11;
          flex-grow: 11;
}

.col-1 {
  -webkit-flex-basis: 8.3333333333%;
      -ms-flex-preferred-size: 8.3333333333%;
          flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  -webkit-flex-basis: 16.6666666667%;
      -ms-flex-preferred-size: 16.6666666667%;
          flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  -webkit-flex-basis: 25%;
      -ms-flex-preferred-size: 25%;
          flex-basis: 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-flex-basis: 33.3333333333%;
      -ms-flex-preferred-size: 33.3333333333%;
          flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  -webkit-flex-basis: 41.6666666667%;
      -ms-flex-preferred-size: 41.6666666667%;
          flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  -webkit-flex-basis: 50%;
      -ms-flex-preferred-size: 50%;
          flex-basis: 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-flex-basis: 58.3333333333%;
      -ms-flex-preferred-size: 58.3333333333%;
          flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  -webkit-flex-basis: 66.6666666667%;
      -ms-flex-preferred-size: 66.6666666667%;
          flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  -webkit-flex-basis: 75%;
      -ms-flex-preferred-size: 75%;
          flex-basis: 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-flex-basis: 83.3333333333%;
      -ms-flex-preferred-size: 83.3333333333%;
          flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  -webkit-flex-basis: 91.6666666667%;
      -ms-flex-preferred-size: 91.6666666667%;
          flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  -webkit-flex-basis: 100%;
      -ms-flex-preferred-size: 100%;
          flex-basis: 100%;
  max-width: 100%;
}

@media only screen and (max-width: 1299px) {
  .innerContainer {
    width: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  .grid {
    /*&:not([data-gutter-width]):not(.grid-nowrap) { // only apply to NON-resizable grids
        .col {
            flex: 100%;
            max-width: 100%;
        }
    }*/
  }

  .grid-reverse-columns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }

  .grid-reverse-rows {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }
}
.col.col-center-y {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.innerContainer {
  margin: 0 auto;
  max-width: 100%;
  width: -webkit-calc(1300px + 4rem);
  width: calc(1300px + 4rem);
  padding-left: 2rem;
  padding-right: 2rem;
}
@media (max-width: 767px) {
  .innerContainer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.fullWidthContainer {
  padding-left: 5rem;
  padding-right: 5rem;
}
@media (max-width: 1500px) {
  .fullWidthContainer {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 767px) {
  .fullWidthContainer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.innerContainer .innerContainer,
.fullWidthContainer .innerContainer {
  width: 1300px;
  margin: 0 auto;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.gridTestingPage .bd .grid {
  padding: 2px 0;
  /*border: 2px solid purple;*/
  background-color: rgba(218, 112, 214, 0.5);
}
.gridTestingPage .bd .grid .col {
  /*border: 2px dashed grey;*/
  min-height: 200px;
  background-color: rgba(127, 255, 212, 0.5);
}

/*@import '_typography.scss';*/
::-moz-selection {
  background: #00D3FF;
  /* WebKit/Blink Browsers */
}
::selection {
  background: #00D3FF;
  /* WebKit/Blink Browsers */
}

::-webkit-input-placeholder {
  color: black;
  text-transform: uppercase;
}

:-moz-placeholder {
  color: black;
  text-transform: uppercase;
}

::-moz-placeholder {
  color: black;
  text-transform: uppercase;
}

:-ms-input-placeholder {
  color: black;
  text-transform: uppercase;
}

::-moz-selection {
  background: black;
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

/*#region TYPOGRAPHY SECTION*/
html {
  font-family: "canada-type-gibson", Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.4em;
}
html {
  font-size: 16px;
}
@media screen and (min-width: 1025px) {
  html {
    font-size: -webkit-calc(16px + 4 * ((100vw - 1025px) / 275));
    font-size: calc(16px + 4 * ((100vw - 1025px) / 275));
  }
}
@media screen and (min-width: 1300px) {
  html {
    font-size: 20px;
  }
}

html, form {
  height: 100%;
}

body {
  overflow-x: hidden;
  background-color: #ffffff;
  color: #000000;
  font-family: "canada-type-gibson", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
}

span.seperator {
  padding: 0 7px;
  color: #1a1a1a;
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
  color: #0047BB;
  font-family: "canada-type-gibson", Arial, sans-serif;
  font-style: normal;
  line-height: 1em;
  margin: 1em 0 1em 0;
  padding: 0;
}
h1.centered, h2.centered, h3.centered, h4.centered, h5.centered, h6.centered {
  text-align: center;
}

h1 {
  font-size: 3em;
  color: #000000;
  margin: 0.75em 0 0.5em 0;
}

h2 {
  font-size: 1.8em;
  font-weight: 700;
}

h3 {
  font-size: 1.3em;
  font-weight: 700;
}

h4 {
  font-size: 1em;
  font-weight: 700;
}

h5 {
  /*font-size: .83em; // popular size*/
  font-size: 1em;
  font-weight: 700;
}

h6 {
  /*font-size: 18px;*/
  /*font-size: .75em; // popular size*/
  font-size: 0.75em;
  font-weight: 700;
}

p {
  margin: 0 0 1em 0;
  padding: 0;
  font-size: 1em;
  font-weight: 400;
  font-style: normal;
  line-height: 1.2em;
}

li {
  font-size: 1em;
  font-style: normal;
}

strong, b {
  font-weight: 700;
  color: #0047BB;
  font-style: normal;
}

em, i {
  font-style: italic;
}

sup {
  vertical-align: super;
  font-size: 50%;
  font-style: normal;
}

sub {
  vertical-align: sub;
  font-size: 50%;
  font-style: normal;
}

blockquote {
  margin: 1em 0;
  padding: 1.5em 1em;
  position: relative;
  border-top: 2px solid #0047BB;
  border-bottom: 2px solid #0047BB;
  color: #0047BB;
  text-align: center;
}
blockquote p {
  color: #0047BB;
  font-weight: 700;
  font-style: normal;
}

a {
  color: #0047BB;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

a:link {
  /*color: $primaryColour;*/
  text-decoration: none;
}

a:visited {
  /*color: $primaryColour;*/
  text-decoration: none;
}

a:hover {
  color: #0047BB;
  text-decoration: underline;
}

a:active {
  color: black;
  text-decoration: none;
}

hr {
  border: none;
  height: 1px;
  margin: 2em 0;
  background-color: #E4EAED;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul li,
ol li {
  list-style: none;
}

button {
  cursor: pointer;
}

/*#endregion TYPOGRAPHY SECTION*/
/*#region LAYOUT SECTION*/
/*Sticky Footer Items */
body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}

#wrapper {
  overflow: hidden;
  height: auto !important;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
}
.k-ie11 #wrapper {
  -webkit-box-flex: 0;
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
}

/* End Sticky Footer Items */
header {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background-color: white;
}
header .sf-Long-text {
  line-height: 1em;
  font-size: 1em;
  color: #ffffff;
}
header .topHeader {
  display: none;
}
header .header {
  padding: 1rem;
  z-index: 100;
  color: #fff;
}
header .header .topHeaderGrid {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
header .header .topHeaderGrid .social {
  padding-right: 1em;
}
header .header .topHeaderGrid .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
}
header .header .topHeaderGrid .search input {
  width: -webkit-calc(100% - 49px);
  width: calc(100% - 49px);
  height: 33px;
}
header .header .topHeaderGrid .search button {
  position: relative;
  color: transparent;
  font-size: 20px;
  border-radius: 2px;
  padding: 0.25em;
}
header .header .topHeaderGrid .search button:after {
  content: "";
  color: #fff;
  display: inline-block;
  position: absolute;
  line-height: 1em;
  height: 25px;
  width: 25px;
  left: 8px;
  top: 7px;
}
header .header .topHeaderGrid .search button:hover {
  color: transparent;
}
header .header.stuck {
  top: 0;
  left: 0;
  width: 100%;
  position: fixed;
  -webkit-box-shadow: 0 0 37px -36px rgba(7, 28, 44, 0.8);
          box-shadow: 0 0 37px -36px rgba(7, 28, 44, 0.8);
}
header .header .headerGrid {
  /*display: flex;*/
  /*@include grid("auto", 1rem);*/
}
header .header .headerGrid .logo {
  color: #fff;
  min-width: 250px;
  width: 20%;
}
header .header .headerGrid .logo img {
  width: 100%;
  max-width: 350px;
}
header .header .headerGrid .menu {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
header .header .headerGrid .menu nav {
  min-width: 75%;
}

.searchModal {
  visibility: hidden !important;
  opacity: 0 !important;
  -webkit-transform: scale(0.9) !important;
  transform: scale(0.9) !important;
  -webkit-transition: opacity 0.2s, visibility 0s 0.2s, -webkit-transform 0.2s !important;
  transition: opacity 0.2s, visibility 0s 0.2s, -webkit-transform 0.2s !important;
  transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s !important;
  transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s, -webkit-transform 0.2s !important;
  text-align: center !important;
}
.popup_visible .searchModal {
  visibility: visible !important;
  opacity: 1 !important;
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important;
  -webkit-transition: -webkit-transform 0.4s, opacity 0.4s !important;
  -webkit-transition: opacity 0.4s, -webkit-transform 0.4s !important;
  transition: opacity 0.4s, -webkit-transform 0.4s !important;
  transition: transform 0.4s, opacity 0.4s !important;
  transition: transform 0.4s, opacity 0.4s, -webkit-transform 0.4s !important;
}
.searchModal .close {
  background: transparent !important;
  margin: 1em auto;
}
.searchModal .formGroup {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.searchModal .formGroup > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  width: auto;
}
.searchModal .formGroup input[type=search] {
  width: 60vw;
  max-width: 100%;
  color: black;
  padding: 0.2em 1em;
  font-size: 2rem;
  border: none;
  background-repeat: no-repeat;
  background-position: right 1em center;
  text-overflow: ellipsis;
}
.searchModal .formGroup input[type=search]::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.searchModal .formGroup input[type=search]:-moz-placeholder {
  opacity: 1;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.searchModal .formGroup input[type=search]::-moz-placeholder {
  opacity: 1;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.searchModal .formGroup input[type=search]:-ms-input-placeholder {
  opacity: 1;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.searchModal .formGroup input[type=search]:hover::-webkit-input-placeholder, .searchModal .formGroup input[type=search]:focus::-webkit-input-placeholder {
  opacity: 0.4;
}
.searchModal .formGroup input[type=search]:hover:-moz-placeholder, .searchModal .formGroup input[type=search]:focus:-moz-placeholder {
  opacity: 0.4;
}
.searchModal .formGroup input[type=search]:hover::-moz-placeholder, .searchModal .formGroup input[type=search]:focus::-moz-placeholder {
  opacity: 0.4;
}
.searchModal .formGroup input[type=search]:hover:-ms-input-placeholder, .searchModal .formGroup input[type=search]:focus:-ms-input-placeholder {
  opacity: 0.4;
}
.searchModal .formGroup input[type=search]:focus {
  outline: 2px solid #003488;
}
.searchModal .formGroup .sfsearchSubmit {
  height: 4rem;
  margin: 0;
  min-width: initial;
  text-transform: none;
  font-weight: 300;
  font-size: 1.2rem;
  margin-left: 0.3rem;
}

.searchModalBackground {
  background-color: rgba(0, 0, 0, 0.87) !important;
  visibility: hidden !important;
  opacity: 0 !important;
  -webkit-transform: scale(0.9) !important;
  transform: scale(0.9) !important;
  -webkit-transition: opacity 0.2s, visibility 0s 0.2s, -webkit-transform 0.2s !important;
  transition: opacity 0.2s, visibility 0s 0.2s, -webkit-transform 0.2s !important;
  transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s !important;
  transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s, -webkit-transform 0.2s !important;
}
.searchModalBackground.open {
  visibility: visible !important;
  opacity: 1 !important;
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important;
  -webkit-transition: -webkit-transform 0.4s, opacity 0.4s !important;
  -webkit-transition: opacity 0.4s, -webkit-transform 0.4s !important;
  transition: opacity 0.4s, -webkit-transform 0.4s !important;
  transition: transform 0.4s, opacity 0.4s !important;
  transition: transform 0.4s, opacity 0.4s, -webkit-transform 0.4s !important;
}

.searchModalBackground {
  background-color: rgba(0, 0, 0, 0.87) !important;
  visibility: hidden !important;
  opacity: 0 !important;
  -webkit-transform: scale(0.9) !important;
  transform: scale(0.9) !important;
  -webkit-transition: opacity 0.2s, visibility 0s 0.2s, -webkit-transform 0.2s !important;
  transition: opacity 0.2s, visibility 0s 0.2s, -webkit-transform 0.2s !important;
  transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s !important;
  transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s, -webkit-transform 0.2s !important;
}
.searchModalBackground.open {
  visibility: visible !important;
  opacity: 1 !important;
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important;
  -webkit-transition: -webkit-transform 0.4s, opacity 0.4s !important;
  -webkit-transition: opacity 0.4s, -webkit-transform 0.4s !important;
  transition: opacity 0.4s, -webkit-transform 0.4s !important;
  transition: transform 0.4s, opacity 0.4s !important;
  transition: transform 0.4s, opacity 0.4s, -webkit-transform 0.4s !important;
}

.searchResults > ul.grid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.searchResults > ul.grid > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 4) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}
.searchResults > ul.grid > .col {
  margin-bottom: 1em;
}

div.searchPager {
  margin: 2em 0;
  text-align: center;
}
div.searchPager ul.pagination {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
div.searchPager ul.pagination > li {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  width: auto;
}
div.searchPager ul.pagination li {
  margin-top: 1em;
}
div.searchPager ul.pagination li.active a {
  color: #0047BB;
}

.pageBanner {
  color: white;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /*padding: 2em;*/
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 25rem;
}
.pageBanner .bannerImage .bgImg {
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /*&:after {
      position: relative;
      display: block;
      height: 100%;
      content: " ";
      background-image: linear-gradient(to right, rgba($secondaryColour,0.4),rgba($secondaryColour,0) 100%);
  }*/
}
.pageBanner .bannerImage .slick-slider {
  width: 100%;
  height: 100%;
  position: relative;
}
.pageBanner .bannerImage .slick-slider .slick-list {
  width: 100%;
  height: 100%;
  position: relative;
}
.pageBanner .bannerImage .slick-slider .slick-list .slick-track {
  width: 100%;
  height: 100%;
  position: relative;
}
.pageBanner .bannerImage .slick-slider .slide {
  width: 100%;
  height: 100%;
  position: relative;
}
.pageBanner .bannerTitle {
  display: inline-block;
  position: relative;
  z-index: 1;
}
.pageBanner .bannerTitle h1 {
  color: #fff;
  font-size: 3.25em;
  width: 100vw;
  background-color: rgba(0, 71, 187, 0.8);
  padding: 0.5em 0.5em 0.5em 1em;
  margin-left: -1em;
  max-width: 70vw;
}

/* Page Title (Sometimes in banner sometimes in header or body) */
span.pseudoH1 {
  font-family: "canada-type-gibson", Arial, sans-serif;
  font-weight: 700;
  margin: 1em 0 0.35em 0;
  color: #fff;
  font-size: 4em;
  line-height: 1.25em;
}

/*.branded {
    @extend %brandedHeading;
}*/
.sf-Long-text,
.cmsContent {
  color: #000000;
  font-size: 1em;
  line-height: 1.4em;
  /* Headings */
  /* Links */
  /* Paragraphs */
  /* Unordered Lists */
  /* Ordered Lists */
  /* Special lists:   Alpha & Roman Numerals */
  /* Misc content elements */
}
.sf-Long-text img,
.cmsContent img {
  max-width: 100%;
}
.sf-Long-text em, .sf-Long-text i,
.cmsContent em,
.cmsContent i {
  font-style: italic;
}
.sf-Long-text h1,
.cmsContent h1 {
  color: inherit;
}
.sf-Long-text h3,
.cmsContent h3 {
  color: #0047BB;
}
.sf-Long-text a:not(.button),
.cmsContent a:not(.button) {
  color: #0047BB;
}
.sf-Long-text a:not(.button):hover,
.cmsContent a:not(.button):hover {
  color: #003488;
}
.sf-Long-text p,
.cmsContent p {
  line-height: 1.4em;
}
.sf-Long-text ul:not(.reset),
.cmsContent ul:not(.reset) {
  list-style-type: none;
  margin: 1em 0;
}
.sf-Long-text ul:not(.reset) > li,
.cmsContent ul:not(.reset) > li {
  list-style-type: none;
  margin: 0;
  padding: 0.2em 0;
  padding-left: 1.75em;
  position: relative;
  font-size: 1em;
}
.sf-Long-text ul:not(.reset) > li:before,
.cmsContent ul:not(.reset) > li:before {
  top: 2.5em;
  left: 3em;
  color: #0047BB;
  content: "";
  display: inline-block;
  position: absolute;
  font-size: 0.3em;
  line-height: 1em;
}
.sf-Long-text ul:not(.reset) > li ul,
.cmsContent ul:not(.reset) > li ul {
  margin: 0;
  margin-top: 0.5em;
}
.sf-Long-text ul:not(.reset) > li ol,
.cmsContent ul:not(.reset) > li ol {
  margin: 0;
  margin-top: 0.5em;
}
.sf-Long-text ul:not(.reset) > li ol > li,
.cmsContent ul:not(.reset) > li ol > li {
  /*list-style-type: decimal;
  list-style-position: inside;
  background: none;
  margin: 0;
  padding: 0.2em 0;*/
}
.sf-Long-text ol:not(.reset),
.cmsContent ol:not(.reset) {
  margin: 1em 0;
  counter-reset: customListCounter 0;
  list-style-position: outside;
}
.sf-Long-text ol:not(.reset) > li,
.cmsContent ol:not(.reset) > li {
  list-style-type: none;
  list-style-position: inside;
  margin: 0;
  position: relative;
  padding: 0.2em 0;
  padding-left: 1.75em;
  counter-increment: customListCounter;
}
.sf-Long-text ol:not(.reset) > li:before,
.cmsContent ol:not(.reset) > li:before {
  top: 0.2em;
  left: 0.4em;
  content: counter(customListCounter) ".";
  color: #003488;
  position: absolute;
  font-weight: 700;
}
.sf-Long-text ol:not(.reset) > li ol,
.cmsContent ol:not(.reset) > li ol {
  margin: 0;
  margin-top: 0.5em;
}
.sf-Long-text ol:not(.reset) > li ol > li,
.cmsContent ol:not(.reset) > li ol > li {
  background: none;
  margin: 0;
}
.sf-Long-text ol:not(.reset) > li ol > li:before,
.cmsContent ol:not(.reset) > li ol > li:before {
  color: black;
}
.sf-Long-text ol:not(.reset) > li ul,
.cmsContent ol:not(.reset) > li ul {
  margin: 0;
  margin-top: 0.5em;
}
.sf-Long-text ol:not(.reset) > li ul > li,
.cmsContent ol:not(.reset) > li ul > li {
  /*list-style-type: none;
  list-style-position: inside;*/
}
.sf-Long-text ul:not(.reset).roman > li,
.sf-Long-text ol:not(.reset).roman > li,
.cmsContent ul:not(.reset).roman > li,
.cmsContent ol:not(.reset).roman > li {
  padding-left: 2em;
}
.sf-Long-text ul:not(.reset).roman > li:before,
.sf-Long-text ol:not(.reset).roman > li:before,
.cmsContent ul:not(.reset).roman > li:before,
.cmsContent ol:not(.reset).roman > li:before {
  content: counter(customListCounter, lower-roman) ".";
}
.sf-Long-text ul:not(.reset).alpha > li:before, .sf-Long-text ul:not(.reset).lettered > li:before,
.sf-Long-text ol:not(.reset).alpha > li:before,
.sf-Long-text ol:not(.reset).lettered > li:before,
.cmsContent ul:not(.reset).alpha > li:before,
.cmsContent ul:not(.reset).lettered > li:before,
.cmsContent ol:not(.reset).alpha > li:before,
.cmsContent ol:not(.reset).lettered > li:before {
  content: counter(customListCounter, lower-alpha) ".";
}
.sf-Long-text ul:not(.reset).upper > li:before, .sf-Long-text ul:not(.reset).uppercase > li:before,
.sf-Long-text ol:not(.reset).upper > li:before,
.sf-Long-text ol:not(.reset).uppercase > li:before,
.cmsContent ul:not(.reset).upper > li:before,
.cmsContent ul:not(.reset).uppercase > li:before,
.cmsContent ol:not(.reset).upper > li:before,
.cmsContent ol:not(.reset).uppercase > li:before {
  text-transform: uppercase;
}
.sf-Long-text .flexCenter,
.cmsContent .flexCenter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 2em 0 !important;
}
.sf-Long-text .flexCenter li,
.cmsContent .flexCenter li {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  list-style: none;
}
.sf-Long-text .flexCenter li:before,
.cmsContent .flexCenter li:before {
  content: none !important;
}
.sf-Long-text address,
.cmsContent address {
  margin: 0.5em 0 1em;
}
.sf-Long-text mark,
.cmsContent mark {
  background-color: #00D3FF;
}

main {
  margin: 0 auto;
  max-width: 2160px;
  /*padding-top: $totalHeaderHeight;*/
}
main .bd .breadCrumb .breadCrumbInner {
  padding: 5px 0;
  font-size: 12px;
}
main .bd .leftContent > .sf_colsIn {
  padding-right: 2em;
}
main .bd .mainContent .sfContentBlock img {
  max-width: 100%;
}
main .bd .secondaryContent {
  margin-top: 2em !important;
}

footer {
  position: relative;
  z-index: 1;
}
footer .preFooter {
  display: none;
}
footer .mailchimpAbsolute {
  position: absolute;
}
footer .mainFooter {
  padding: 1.5em 0;
  background-color: #0047BB;
  font-size: 13px;
  color: #ffffff;
}
footer .mainFooter h3, footer .mainFooter p, footer .mainFooter p a, footer .mainFooter a {
  color: #ffffff !important;
}
footer .mainFooter .sf-Long-text a.button {
  color: #0047BB !important;
}
footer .mainFooter .logo {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
footer .mainFooter .subscribe {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
footer .mainFooter .subscribe .button {
  background-color: #fff;
  color: #0047BB;
  padding: 1em 2em;
  border-radius: 4px;
}
footer .mainFooter .social {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
footer .mainFooter .social .socialLinks a {
  color: #fff;
  font-size: 1.5rem;
}
footer .bottomFooter {
  font-size: 0.8rem;
}
footer .bottomFooter .awardsWrapper {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding: 2em 0 1em;
}
footer .bottomFooter .awardsWrapper .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
}
footer .bottomFooter .siteMapWrapper {
  border-top: solid 1px #005aee;
  padding: 1em 0;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
footer .bottomFooter .siteMapWrapper .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
}
footer .bottomFooter .siteMapWrapper ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
footer .bottomFooter .siteMapWrapper ul li {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding-right: 1em;
}

/*#endregion LAYOUT SECTION*/
/*#region SOCIAL LINKS AND ICONS*/
ul.socialLinks {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  font-size: 37px;
  font-family: "Font Awesome 5 Pro";
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  margin-left: 0.5rem;
}
ul.socialLinks > li {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  width: auto;
}
ul.socialLinks li {
  margin: 0 0.25em 0 0;
}
ul.socialLinks li em {
  font-size: 36px;
}

ul.sharingLinks {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
ul.sharingLinks > li {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  width: auto;
}
ul.sharingLinks li.sharingLink button {
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  background-image: none;
  color: #0047BB;
  font-size: 1.8rem;
}
ul.sharingLinks li.sharingLink button i {
  color: inherit;
}
ul.sharingLinks li.sharingLink button:hover, ul.sharingLinks li.sharingLink button:focus ul.sharingLinks li.sharingLink button:active {
  color: #003488;
}

div.socialAndSharing {
  padding: 2em 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
div.socialAndSharing > ul {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  width: auto;
}
div.socialAndSharing ul.socialLinks {
  font-size: 1.8rem;
}
div.socialAndSharing ul.sharingLinks {
  font-size: 1.8rem;
}

/*#endregion SocialLinks SECTION*/
/*#region FORMS */
.pageWrapper label,
footer label,
.popup_wrapper label {
  cursor: pointer;
}
.pageWrapper fieldset,
footer fieldset,
.popup_wrapper fieldset {
  padding: 0;
  border: none;
}
.pageWrapper input[type=checkbox],
footer input[type=checkbox],
.popup_wrapper input[type=checkbox] {
  cursor: pointer;
  width: 1px;
  height: 1px;
  position: absolute;
  visibility: hidden;
}
.pageWrapper input[type=checkbox] + label,
footer input[type=checkbox] + label,
.popup_wrapper input[type=checkbox] + label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 2em;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.pageWrapper input[type=checkbox] + label::-moz-selection, footer input[type=checkbox] + label::-moz-selection, .popup_wrapper input[type=checkbox] + label::-moz-selection {
  background-color: transparent;
}
.pageWrapper input[type=checkbox] + label::selection,
footer input[type=checkbox] + label::selection,
.popup_wrapper input[type=checkbox] + label::selection {
  background-color: transparent;
}
.pageWrapper input[type=checkbox] + label:hover,
footer input[type=checkbox] + label:hover,
.popup_wrapper input[type=checkbox] + label:hover {
  color: #0047BB;
}
.pageWrapper input[type=checkbox] + label:before, .pageWrapper input[type=checkbox] + label:after,
footer input[type=checkbox] + label:before,
footer input[type=checkbox] + label:after,
.popup_wrapper input[type=checkbox] + label:before,
.popup_wrapper input[type=checkbox] + label:after {
  top: 0;
  left: 0;
  display: inline-block;
  position: absolute;
  font-size: 1rem;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  margin-right: 0.4em;
  font-family: "Font Awesome 5 Pro";
}
.pageWrapper input[type=checkbox] + label:before,
footer input[type=checkbox] + label:before,
.popup_wrapper input[type=checkbox] + label:before {
  content: "";
  font-weight: 900;
  color: white;
}
.pageWrapper input[type=checkbox] + label:after,
footer input[type=checkbox] + label:after,
.popup_wrapper input[type=checkbox] + label:after {
  content: "";
  font-weight: 300;
  color: #0047BB;
}
.pageWrapper input[type=checkbox]:checked + label:after,
footer input[type=checkbox]:checked + label:after,
.popup_wrapper input[type=checkbox]:checked + label:after {
  content: "";
}
.pageWrapper input[type=text], .pageWrapper input[type=email], .pageWrapper input[type=search], .pageWrapper input[type=number], .pageWrapper input[type=password],
footer input[type=text],
footer input[type=email],
footer input[type=search],
footer input[type=number],
footer input[type=password],
.popup_wrapper input[type=text],
.popup_wrapper input[type=email],
.popup_wrapper input[type=search],
.popup_wrapper input[type=number],
.popup_wrapper input[type=password] {
  width: 100%;
  /*max-width: 280px;*/
  color: black;
  padding: 0.25em;
  height: 2.6em;
  border: 1px solid #0047BB;
  background-repeat: no-repeat;
  background-position: right 1em center;
  text-overflow: ellipsis;
}
.pageWrapper input[type=text]::-webkit-input-placeholder, .pageWrapper input[type=email]::-webkit-input-placeholder, .pageWrapper input[type=search]::-webkit-input-placeholder, .pageWrapper input[type=number]::-webkit-input-placeholder, .pageWrapper input[type=password]::-webkit-input-placeholder,
footer input[type=text]::-webkit-input-placeholder,
footer input[type=email]::-webkit-input-placeholder,
footer input[type=search]::-webkit-input-placeholder,
footer input[type=number]::-webkit-input-placeholder,
footer input[type=password]::-webkit-input-placeholder,
.popup_wrapper input[type=text]::-webkit-input-placeholder,
.popup_wrapper input[type=email]::-webkit-input-placeholder,
.popup_wrapper input[type=search]::-webkit-input-placeholder,
.popup_wrapper input[type=number]::-webkit-input-placeholder,
.popup_wrapper input[type=password]::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.pageWrapper input[type=text]:-moz-placeholder, .pageWrapper input[type=email]:-moz-placeholder, .pageWrapper input[type=search]:-moz-placeholder, .pageWrapper input[type=number]:-moz-placeholder, .pageWrapper input[type=password]:-moz-placeholder,
footer input[type=text]:-moz-placeholder,
footer input[type=email]:-moz-placeholder,
footer input[type=search]:-moz-placeholder,
footer input[type=number]:-moz-placeholder,
footer input[type=password]:-moz-placeholder,
.popup_wrapper input[type=text]:-moz-placeholder,
.popup_wrapper input[type=email]:-moz-placeholder,
.popup_wrapper input[type=search]:-moz-placeholder,
.popup_wrapper input[type=number]:-moz-placeholder,
.popup_wrapper input[type=password]:-moz-placeholder {
  opacity: 1;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.pageWrapper input[type=text]::-moz-placeholder, .pageWrapper input[type=email]::-moz-placeholder, .pageWrapper input[type=search]::-moz-placeholder, .pageWrapper input[type=number]::-moz-placeholder, .pageWrapper input[type=password]::-moz-placeholder,
footer input[type=text]::-moz-placeholder,
footer input[type=email]::-moz-placeholder,
footer input[type=search]::-moz-placeholder,
footer input[type=number]::-moz-placeholder,
footer input[type=password]::-moz-placeholder,
.popup_wrapper input[type=text]::-moz-placeholder,
.popup_wrapper input[type=email]::-moz-placeholder,
.popup_wrapper input[type=search]::-moz-placeholder,
.popup_wrapper input[type=number]::-moz-placeholder,
.popup_wrapper input[type=password]::-moz-placeholder {
  opacity: 1;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.pageWrapper input[type=text]:-ms-input-placeholder, .pageWrapper input[type=email]:-ms-input-placeholder, .pageWrapper input[type=search]:-ms-input-placeholder, .pageWrapper input[type=number]:-ms-input-placeholder, .pageWrapper input[type=password]:-ms-input-placeholder,
footer input[type=text]:-ms-input-placeholder,
footer input[type=email]:-ms-input-placeholder,
footer input[type=search]:-ms-input-placeholder,
footer input[type=number]:-ms-input-placeholder,
footer input[type=password]:-ms-input-placeholder,
.popup_wrapper input[type=text]:-ms-input-placeholder,
.popup_wrapper input[type=email]:-ms-input-placeholder,
.popup_wrapper input[type=search]:-ms-input-placeholder,
.popup_wrapper input[type=number]:-ms-input-placeholder,
.popup_wrapper input[type=password]:-ms-input-placeholder {
  opacity: 1;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.pageWrapper input[type=text]:hover::-webkit-input-placeholder, .pageWrapper input[type=text]:focus::-webkit-input-placeholder, .pageWrapper input[type=email]:hover::-webkit-input-placeholder, .pageWrapper input[type=email]:focus::-webkit-input-placeholder, .pageWrapper input[type=search]:hover::-webkit-input-placeholder, .pageWrapper input[type=search]:focus::-webkit-input-placeholder, .pageWrapper input[type=number]:hover::-webkit-input-placeholder, .pageWrapper input[type=number]:focus::-webkit-input-placeholder, .pageWrapper input[type=password]:hover::-webkit-input-placeholder, .pageWrapper input[type=password]:focus::-webkit-input-placeholder,
footer input[type=text]:hover::-webkit-input-placeholder,
footer input[type=text]:focus::-webkit-input-placeholder,
footer input[type=email]:hover::-webkit-input-placeholder,
footer input[type=email]:focus::-webkit-input-placeholder,
footer input[type=search]:hover::-webkit-input-placeholder,
footer input[type=search]:focus::-webkit-input-placeholder,
footer input[type=number]:hover::-webkit-input-placeholder,
footer input[type=number]:focus::-webkit-input-placeholder,
footer input[type=password]:hover::-webkit-input-placeholder,
footer input[type=password]:focus::-webkit-input-placeholder,
.popup_wrapper input[type=text]:hover::-webkit-input-placeholder,
.popup_wrapper input[type=text]:focus::-webkit-input-placeholder,
.popup_wrapper input[type=email]:hover::-webkit-input-placeholder,
.popup_wrapper input[type=email]:focus::-webkit-input-placeholder,
.popup_wrapper input[type=search]:hover::-webkit-input-placeholder,
.popup_wrapper input[type=search]:focus::-webkit-input-placeholder,
.popup_wrapper input[type=number]:hover::-webkit-input-placeholder,
.popup_wrapper input[type=number]:focus::-webkit-input-placeholder,
.popup_wrapper input[type=password]:hover::-webkit-input-placeholder,
.popup_wrapper input[type=password]:focus::-webkit-input-placeholder {
  opacity: 0.4;
}
.pageWrapper input[type=text]:hover:-moz-placeholder, .pageWrapper input[type=text]:focus:-moz-placeholder, .pageWrapper input[type=email]:hover:-moz-placeholder, .pageWrapper input[type=email]:focus:-moz-placeholder, .pageWrapper input[type=search]:hover:-moz-placeholder, .pageWrapper input[type=search]:focus:-moz-placeholder, .pageWrapper input[type=number]:hover:-moz-placeholder, .pageWrapper input[type=number]:focus:-moz-placeholder, .pageWrapper input[type=password]:hover:-moz-placeholder, .pageWrapper input[type=password]:focus:-moz-placeholder,
footer input[type=text]:hover:-moz-placeholder,
footer input[type=text]:focus:-moz-placeholder,
footer input[type=email]:hover:-moz-placeholder,
footer input[type=email]:focus:-moz-placeholder,
footer input[type=search]:hover:-moz-placeholder,
footer input[type=search]:focus:-moz-placeholder,
footer input[type=number]:hover:-moz-placeholder,
footer input[type=number]:focus:-moz-placeholder,
footer input[type=password]:hover:-moz-placeholder,
footer input[type=password]:focus:-moz-placeholder,
.popup_wrapper input[type=text]:hover:-moz-placeholder,
.popup_wrapper input[type=text]:focus:-moz-placeholder,
.popup_wrapper input[type=email]:hover:-moz-placeholder,
.popup_wrapper input[type=email]:focus:-moz-placeholder,
.popup_wrapper input[type=search]:hover:-moz-placeholder,
.popup_wrapper input[type=search]:focus:-moz-placeholder,
.popup_wrapper input[type=number]:hover:-moz-placeholder,
.popup_wrapper input[type=number]:focus:-moz-placeholder,
.popup_wrapper input[type=password]:hover:-moz-placeholder,
.popup_wrapper input[type=password]:focus:-moz-placeholder {
  opacity: 0.4;
}
.pageWrapper input[type=text]:hover::-moz-placeholder, .pageWrapper input[type=text]:focus::-moz-placeholder, .pageWrapper input[type=email]:hover::-moz-placeholder, .pageWrapper input[type=email]:focus::-moz-placeholder, .pageWrapper input[type=search]:hover::-moz-placeholder, .pageWrapper input[type=search]:focus::-moz-placeholder, .pageWrapper input[type=number]:hover::-moz-placeholder, .pageWrapper input[type=number]:focus::-moz-placeholder, .pageWrapper input[type=password]:hover::-moz-placeholder, .pageWrapper input[type=password]:focus::-moz-placeholder,
footer input[type=text]:hover::-moz-placeholder,
footer input[type=text]:focus::-moz-placeholder,
footer input[type=email]:hover::-moz-placeholder,
footer input[type=email]:focus::-moz-placeholder,
footer input[type=search]:hover::-moz-placeholder,
footer input[type=search]:focus::-moz-placeholder,
footer input[type=number]:hover::-moz-placeholder,
footer input[type=number]:focus::-moz-placeholder,
footer input[type=password]:hover::-moz-placeholder,
footer input[type=password]:focus::-moz-placeholder,
.popup_wrapper input[type=text]:hover::-moz-placeholder,
.popup_wrapper input[type=text]:focus::-moz-placeholder,
.popup_wrapper input[type=email]:hover::-moz-placeholder,
.popup_wrapper input[type=email]:focus::-moz-placeholder,
.popup_wrapper input[type=search]:hover::-moz-placeholder,
.popup_wrapper input[type=search]:focus::-moz-placeholder,
.popup_wrapper input[type=number]:hover::-moz-placeholder,
.popup_wrapper input[type=number]:focus::-moz-placeholder,
.popup_wrapper input[type=password]:hover::-moz-placeholder,
.popup_wrapper input[type=password]:focus::-moz-placeholder {
  opacity: 0.4;
}
.pageWrapper input[type=text]:hover:-ms-input-placeholder, .pageWrapper input[type=text]:focus:-ms-input-placeholder, .pageWrapper input[type=email]:hover:-ms-input-placeholder, .pageWrapper input[type=email]:focus:-ms-input-placeholder, .pageWrapper input[type=search]:hover:-ms-input-placeholder, .pageWrapper input[type=search]:focus:-ms-input-placeholder, .pageWrapper input[type=number]:hover:-ms-input-placeholder, .pageWrapper input[type=number]:focus:-ms-input-placeholder, .pageWrapper input[type=password]:hover:-ms-input-placeholder, .pageWrapper input[type=password]:focus:-ms-input-placeholder,
footer input[type=text]:hover:-ms-input-placeholder,
footer input[type=text]:focus:-ms-input-placeholder,
footer input[type=email]:hover:-ms-input-placeholder,
footer input[type=email]:focus:-ms-input-placeholder,
footer input[type=search]:hover:-ms-input-placeholder,
footer input[type=search]:focus:-ms-input-placeholder,
footer input[type=number]:hover:-ms-input-placeholder,
footer input[type=number]:focus:-ms-input-placeholder,
footer input[type=password]:hover:-ms-input-placeholder,
footer input[type=password]:focus:-ms-input-placeholder,
.popup_wrapper input[type=text]:hover:-ms-input-placeholder,
.popup_wrapper input[type=text]:focus:-ms-input-placeholder,
.popup_wrapper input[type=email]:hover:-ms-input-placeholder,
.popup_wrapper input[type=email]:focus:-ms-input-placeholder,
.popup_wrapper input[type=search]:hover:-ms-input-placeholder,
.popup_wrapper input[type=search]:focus:-ms-input-placeholder,
.popup_wrapper input[type=number]:hover:-ms-input-placeholder,
.popup_wrapper input[type=number]:focus:-ms-input-placeholder,
.popup_wrapper input[type=password]:hover:-ms-input-placeholder,
.popup_wrapper input[type=password]:focus:-ms-input-placeholder {
  opacity: 0.4;
}
.pageWrapper input[type=text]:focus, .pageWrapper input[type=email]:focus, .pageWrapper input[type=search]:focus, .pageWrapper input[type=number]:focus, .pageWrapper input[type=password]:focus,
footer input[type=text]:focus,
footer input[type=email]:focus,
footer input[type=search]:focus,
footer input[type=number]:focus,
footer input[type=password]:focus,
.popup_wrapper input[type=text]:focus,
.popup_wrapper input[type=email]:focus,
.popup_wrapper input[type=search]:focus,
.popup_wrapper input[type=number]:focus,
.popup_wrapper input[type=password]:focus {
  outline: 2px solid black;
}
.pageWrapper textarea,
footer textarea,
.popup_wrapper textarea {
  width: 100%;
  display: block;
  min-height: 2.6em;
  border: 1px solid black;
}
.pageWrapper textarea:hover::-webkit-input-placeholder, .pageWrapper textarea:focus::-webkit-input-placeholder,
footer textarea:hover::-webkit-input-placeholder,
footer textarea:focus::-webkit-input-placeholder,
.popup_wrapper textarea:hover::-webkit-input-placeholder,
.popup_wrapper textarea:focus::-webkit-input-placeholder {
  opacity: 0.4;
}
.pageWrapper textarea:hover:-moz-placeholder, .pageWrapper textarea:focus:-moz-placeholder,
footer textarea:hover:-moz-placeholder,
footer textarea:focus:-moz-placeholder,
.popup_wrapper textarea:hover:-moz-placeholder,
.popup_wrapper textarea:focus:-moz-placeholder {
  opacity: 0.4;
}
.pageWrapper textarea:hover::-moz-placeholder, .pageWrapper textarea:focus::-moz-placeholder,
footer textarea:hover::-moz-placeholder,
footer textarea:focus::-moz-placeholder,
.popup_wrapper textarea:hover::-moz-placeholder,
.popup_wrapper textarea:focus::-moz-placeholder {
  opacity: 0.4;
}
.pageWrapper textarea:hover:-ms-input-placeholder, .pageWrapper textarea:focus:-ms-input-placeholder,
footer textarea:hover:-ms-input-placeholder,
footer textarea:focus:-ms-input-placeholder,
.popup_wrapper textarea:hover:-ms-input-placeholder,
.popup_wrapper textarea:focus:-ms-input-placeholder {
  opacity: 0.4;
}
.pageWrapper textarea:focus,
footer textarea:focus,
.popup_wrapper textarea:focus {
  outline: 2px solid black;
}
.pageWrapper select,
footer select,
.popup_wrapper select {
  width: 100%;
  /*max-width: 280px;*/
  color: #0047BB;
  cursor: pointer;
  border: 1px solid #0047BB;
  padding: 0.2em 1em;
  font-size: 1rem;
  line-height: 1;
  font-family: "canada-type-gibson", Arial, sans-serif;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  background-image: url(/CFImages/Icons/triangle-down-purple.svg);
  background-repeat: no-repeat;
  background-position: right 1em center;
  background-size: 0.6em auto;
  padding-right: 1.6em;
  height: 2.6em;
  text-transform: uppercase;
}
.pageWrapper select:focus,
footer select:focus,
.popup_wrapper select:focus {
  outline: none;
  outline: 2px solid black;
}
.pageWrapper select > option,
footer select > option,
.popup_wrapper select > option {
  text-transform: none;
}
.pageWrapper select > option:checked, .pageWrapper select > option:hover,
footer select > option:checked,
footer select > option:hover,
.popup_wrapper select > option:checked,
.popup_wrapper select > option:hover {
  background-color: #E4EAED;
}
/*#endregion FORMS */
/*#region IMAGES */
div.bgImgWrapper {
  max-width: 100%;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: 0;
}
div.bgImgWrapper a {
  display: block;
  width: 100%;
  height: 100%;
}
div.bgImgWrapper a span.bgImg {
  background-position: center;
  background-size: cover;
  display: block;
}
div.bgImgWrapper div.bgImg {
  position: absolute;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

div.imgWrapper {
  width: 100%;
}
div.imgWrapper a img {
  max-width: 100%;
}
div.imgWrapper img {
  max-width: 100%;
}

div.bgImgWrapper.hoverZoom a span.bgImg, div.bgImgWrapper.hoverZoom div.bgImg {
  max-width: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
div.bgImgWrapper.hoverZoom a span.bgImg:hover, div.bgImgWrapper.hoverZoom div.bgImg:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/* Image Zoom in */
.parent:hover .child, .parent:focus .child {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

/*#endregion IMAGES */
/*#region MISCELLANEOUS CLASSES SECTION*/
.equalTopMargins > :first-child {
  margin-top: 1em;
}

/* Combined Search Suggestions Dropdown */
.quickFindWrapper {
  border-bottom: 1px solid #B1B1B1;
  background-color: #0047BB;
  margin-bottom: 10px;
}
.quickFindWrapper .GOButton {
  display: none;
}
.quickFindWrapper .quickFindBox {
  margin: 0 auto;
  display: block;
  max-width: 400px;
  padding: 10px 10px;
  width: 100%;
}
.quickFindWrapper .quickFindBox .rcbInput {
  font-size: 18px;
  padding: 5px 40px;
  color: black;
  background: url(/CFImages/Icons/icon_search.png) no-repeat 10px;
  background-size: 24px 25px;
}
.quickFindWrapper .quickFindBox .rcbInputCell {
  height: 48px !important;
  border: none;
  border-radius: 5px;
}
.quickFindWrapper .quickFindBox .rcbEmptyMessage {
  color: black;
}

.quickFindWrapper .quickFindBox ul, .quickFindWrapper .quickFindBox ul.rcbList {
  list-style-type: none;
}

.quickFindWrapper .quickFindBox ul li, .quickFindWrapper .quickFindBox ul li.rcbItem, .quickFindWrapper .quickFindBox ul.rcbList li, .quickFindWrapper .quickFindBox ul.rcbList li.rcbItem {
  list-style-type: none;
}

/* ### Miscellaneous Global Classes ### */
.forcedHide {
  display: none !important;
}

.hideOnDesktop {
  display: none !important;
}

.hideOnMobile {
  display: inline-block;
}

img.baseImage, .baseImage img {
  display: block;
  width: 100%;
}

.pullQuote {
  padding: 30px 50px;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  font-size: 130%;
  font-weight: 700;
}

.group:after {
  content: "";
  display: table;
  clear: both;
}

.subtle {
  color: #262626;
  font-size: 80%;
}

.clickable:hover {
  cursor: pointer;
}

.scrollToTop {
  width: 2rem;
  height: 2rem;
  display: none;
  top: auto;
  left: auto;
  right: 1rem;
  bottom: 1rem;
  position: fixed;
  font-size: 1.2rem;
  background-color: #0047BB;
  cursor: pointer;
  z-index: 1000;
  border: 3px solid white;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  opacity: 0.4;
}
.scrollToTop:focus, .scrollToTop:hover {
  text-decoration: none;
  background-color: black;
  opacity: 1;
}
.scrollToTop i {
  color: white;
  line-height: 2rem;
  width: 100%;
  text-align: center;
}

#skiptocontent a {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
}
#skiptocontent a:focus, #skiptocontent a:active {
  color: #fff;
  background-color: #000;
  left: auto;
  top: auto;
  width: 30%;
  height: auto;
  overflow: auto;
  margin: 10px 35%;
  padding: 5px;
  border-radius: 15px;
  border: 4px solid #00D3FF;
  text-align: center;
  font-size: 1.2em;
  z-index: 999;
}

/* Special Links */
a[href^=tel], a[href^=Tel] {
  text-decoration: none;
  font-weight: 400;
  font-family: "canada-type-gibson", Arial, sans-serif;
  color: #000000;
  /*&:before {
  content: "\260e";
  margin-right: 0.5em;
  }*/
}
a[href^=tel]:hover, a[href^=Tel]:hover {
  text-decoration: underline;
  cursor: pointer;
  color: inherit;
}

.noLink {
  color: #000000 !important;
}
.noLink:hover {
  text-decoration: none !important;
}

.hidden {
  display: none !important;
}

.stickyLink {
  color: white;
  top: 30%;
  right: 0;
  z-index: 10;
  display: inline-block;
  position: fixed;
  font-size: 1.4rem;
  -webkit-transform: translateY(-100%) rotate(-90deg);
          transform: translateY(-100%) rotate(-90deg);
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  background-color: #003488;
  border-top-left-radius: 0.25em;
  border-top-right-radius: 0.25em;
  overflow: hidden;
  display: none;
}
.stickyLink a {
  color: white;
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0.8rem 1.8rem 0.4rem;
}
.stickyLink a:hover, .stickyLink a:focus, .stickyLink a:active {
  text-decoration: none;
  background-color: #003da2;
}
.stickyLink a:after {
  width: 0.75em;
  height: 0.75em;
  display: inline-block;
  content: " ";
  position: relative;
  background-size: 100% auto;
  background-image: url(/CFImages/Icons/arrow-up-teal.svg);
  background-repeat: no-repeat;
  background-position: center center;
  margin-left: 0.4em;
}

.stickyPhone {
  top: auto;
  left: auto;
  right: 1rem;
  bottom: 5%;
  margin: 0 1rem;
  position: fixed;
  color: white;
  font-size: 2rem;
  z-index: 10;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
@media (min-width: 768px) {
  .stickyPhone {
    display: none;
  }
}
.stickyPhone.left {
  left: 0;
  right: auto;
}
.stickyPhone.right {
  right: 0;
  left: auto;
}
.stickyPhone .stickyPhoneInner {
  color: inherit;
  width: 2em;
  height: 2em;
  line-height: 2em;
  border-radius: 50%;
  overflow: hidden;
  background-color: #003488;
}
.stickyPhone .stickyPhoneInner a {
  color: inherit;
  margin: 0;
  padding: 0;
  min-width: initial;
  display: block;
  line-height: 2em;
  text-align: center;
}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.counterWrapper {
  margin: 7rem 0 4rem;
}
.counterWrapper .counterGrid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -1em;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.counterWrapper .counterGrid > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 1em;
  vertical-align: top;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  width: auto;
}
@media (max-width: 980px) {
  .counterWrapper .counterGrid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -1em;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    font-size: 1.4em;
  }
  .counterWrapper .counterGrid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 1em;
    vertical-align: top;
    /*width: calc((1 / 2) * 100% - 2em);*/
    -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 2em);
        -ms-flex-preferred-size: calc((1 / 2) * 100% - 2em);
            flex-basis: calc((1 / 2) * 100% - 2em);
  }
}
@media (max-width: 767px) {
  .counterWrapper .counterGrid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -1em;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .counterWrapper .counterGrid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 1em;
    vertical-align: top;
    /*width: calc((1 / 2) * 100% - 2em);*/
    -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 2em);
        -ms-flex-preferred-size: calc((1 / 2) * 100% - 2em);
            flex-basis: calc((1 / 2) * 100% - 2em);
  }
}
.counterWrapper .counterGrid .counterWrapper {
  border: solid 3px #003488;
  border-radius: 50%;
  position: relative;
  color: white;
  text-align: center;
  text-transform: uppercase;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto;
  width: 11em;
}
.counterWrapper .counterGrid .counterWrapper:before {
  /* sets the height and then effectively removes itself from flow so as not to interfere with siblings */
  content: "";
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 100%;
}
.counterWrapper .counterGrid .counterWrapper:after {
  /* to clear float */
  content: "";
  display: table;
  clear: both;
}
.counterWrapper .counterGrid .counterWrapper h4 {
  width: 100%;
  color: inherit;
  margin: 0;
  padding: 0;
  font-size: 1.6em;
  line-height: 1em;
  line-height: 0.9em;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.counterWrapper .counterGrid .counterWrapper .value {
  display: block;
  font-weight: 100;
}
.counterWrapper .counterGrid .counterWrapper .counter {
  /*@include fluid-type($L_Min, $XL_Min, 16px, 20px);*/
}

.googleMapsOverlay {
  background: transparent;
  position: relative;
  width: 100%;
  height: 500px;
  top: 500px;
  /* your iframe height */
  margin-top: -500px;
  /* your iframe height */
}

/*#endregion MISCELLANEOUS CLASSES SECTION*/
/*#region OPEN STREET MAPS */
.openStreetMap {
  min-height: 400px;
  background-color: #cccccc;
  margin: 1em 0;
}

.mapbox-improve-map {
  display: none;
}

.marker {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
.marker:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  color: #0047BB;
  font-family: "Font Awesome 5 Pro";
  font-size: 50px;
  font-weight: 700;
}

.mapboxgl-popup {
  max-width: 300px;
}
.mapboxgl-popup .mapboxgl-popup-content {
  padding: 1.2rem;
  text-align: center;
  font-family: "canada-type-gibson", Arial, sans-serif;
  font-size: 1.3em;
}

/*#endregion OPEN STREET MAPS */
/*#region contentTypeLabels SECTION*/
.contentTypeLabels span.labelValuesSeperator:before {
  content: ": ";
}
.contentTypeLabels span.seperator:before {
  content: " | ";
}

/*#endregion contentTypeLabels SECTION*/
/*#region dates SECTION*/
/*#endregion dates SECTION*/
/* #region HOME SECTION*/
.carousel .slide, .carousel .slick-slide, .slick-slider .slide, .slick-slider .slick-slide {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /*display: none;*/
  outline: none !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0 10px;
}
.carousel .slick-track, .slick-slider .slick-track {
  outline: none !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}
.carousel .slick-track .slide, .carousel .slick-track .slick-slide, .slick-slider .slick-track .slide, .slick-slider .slick-track .slick-slide {
  height: auto;
}
.carousel .slide:not(:first-child), .carousel .slick-slide:not(:first-child), .slick-slider .slide:not(:first-child), .slick-slider .slick-slide:not(:first-child) {
  display: none;
}
.carousel .slick-arrow, .slick-slider .slick-arrow {
  background: none;
  color: #000;
}
.carousel .slick-arrow:hover, .slick-slider .slick-arrow:hover {
  background-color: transparent !important;
}
.carousel .slick-arrow:before, .slick-slider .slick-arrow:before {
  color: #0047BB;
}
.carousel.slick-initialized .slide, .carousel.slick-initialized .slick-slide, .slick-slider.slick-initialized .slide, .slick-slider.slick-initialized .slick-slide {
  display: block;
}
.carousel.slick-initialized .slide.slick-current .innerContent, .carousel.slick-initialized .slide.slick-active .innerContent, .carousel.slick-initialized .slick-slide.slick-current .innerContent, .carousel.slick-initialized .slick-slide.slick-active .innerContent, .slick-slider.slick-initialized .slide.slick-current .innerContent, .slick-slider.slick-initialized .slide.slick-active .innerContent, .slick-slider.slick-initialized .slick-slide.slick-current .innerContent, .slick-slider.slick-initialized .slick-slide.slick-active .innerContent {
  /*opacity: 1;*/
  -webkit-animation-name: fade-in;
          animation-name: fade-in;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
  -webkit-animation-delay: 200ms;
          animation-delay: 200ms;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
}

.aboutWrapper {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.aboutWrapper .left {
  padding: 2rem 1rem 2rem 0;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -webkit-flex-basis: -webkit-calc(650px - 4rem);
      -ms-flex-preferred-size: calc(650px - 4rem);
          flex-basis: calc(650px - 4rem);
}
.aboutWrapper .right {
  -webkit-flex-basis: 50%;
      -ms-flex-preferred-size: 50%;
          flex-basis: 50%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}
.aboutWrapper .right .bgImg {
  height: 100%;
  background: url(/CFImages/banners/windmills.jpg);
}

.teamWrapper {
  background: linear-gradient(to bottom, #0047BB 500px, #ffffff 500px);
  padding-top: 2rem;
}
.teamWrapper .sf-Long-text, .teamWrapper .cmsContent {
  color: #fff;
}
.teamWrapper .sf-Long-text h2, .teamWrapper .cmsContent h2 {
  color: #fff;
}

.resourceWrapper {
  margin-top: 2em;
  padding: 1em;
  background-color: #E4EAED;
}

.homeResources .itemImage {
  min-height: 500px;
}
.homeResources .resources .innerContent {
  position: relative;
  border: none;
  padding: 5rem;
}
.homeResources .odd article {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

/*#region offices SECTION*/
.offices > .grid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 0;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.offices > .grid > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0;
  vertical-align: top;
  /*width: calc((1 / 1) * 100%);*/
  -webkit-flex-basis: -webkit-calc((1 / 1) * 100%);
      -ms-flex-preferred-size: calc((1 / 1) * 100%);
          flex-basis: calc((1 / 1) * 100%);
}
.offices > .grid > .col .itemImage {
  min-height: 700px;
}
.offices > .grid > .col .itemContent {
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-content: center;
      -ms-flex-line-pack: center;
          align-content: center;
}
.offices > .grid > .col:nth-child(even) article {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.offices > .grid .col:last-child {
  margin-bottom: 2em;
}

.office .pageBanner {
  min-height: 700px;
}
.office .pageBanner .bannerImage .bgImg {
  background-position: top;
}
.office .pageBanner .innerContainer {
  max-height: 550px;
  color: #fff;
  overflow: hidden;
}
.office .pageBanner .innerContainer .extraBannerContent {
  position: relative;
  z-index: 2;
  width: 50%;
}
.office .placeOverBanner {
  position: absolute;
  margin-top: -200px;
  left: 5em;
  color: #fff;
}
.office .placeOverBanner a {
  color: #fff;
}
.office .placeOverBanner ul.officeContact {
  padding: 0.5em 0;
}

.homeSlideshow {
  position: relative;
}
.homeSlideshow .slide {
  background-color: black;
  color: white;
  overflow: hidden;
  position: relative;
  -webkit-transition: all 1s ease-out;
  transition: all 1s ease-out;
}
.homeSlideshow .slide .bgImgWrapper .bgImg {
  background-attachment: fixed;
}
.k-edge .homeSlideshow .slide .bgImgWrapper .bgImg {
  background-attachment: inherit;
}
.k-ie .homeSlideshow .slide .bgImgWrapper .bgImg {
  background-attachment: inherit;
}
.homeSlideshow .slide .bgImgWrapper .bgImg:before {
  position: relative;
  display: block;
  height: 100%;
  content: " ";
  -webkit-box-shadow: 0 0 200px rgba(0, 0, 0, 0.8) inset;
          box-shadow: 0 0 200px rgba(0, 0, 0, 0.8) inset;
  opacity: 0.6;
  z-index: 2;
}
.homeSlideshow .slide .pageBanner {
  height: 34rem;
}
.homeSlideshow .slide .pageBanner .bannerTitle h1.pageTitle {
  font-size: 3rem;
  padding-left: 2rem;
  margin: 0;
}
.homeSlideshow .slide .pageBanner .bannerTitle h1.pageTitle:after {
  content: none;
}
.homeSlideshow .slide .pageBanner .bannerTitle .summary {
  background-color: #f2f0f0;
  color: #0047BB;
  width: 50vw;
  position: relative;
  padding: 2rem;
}
.homeSlideshow .slide .pageBanner .bannerTitle .summary:before {
  content: "";
  height: 100%;
  width: 50vw;
  background-color: #f2f0f0;
  position: absolute;
  left: -100%;
  top: 0;
}
.homeSlideshow .slide .contentWrapper {
  position: relative;
  /*height: approx 850px;*/
  z-index: 4;
  width: -webkit-calc(2160px - 2rem);
  width: calc(2160px - 2rem);
  padding-left: 5rem;
  padding-right: 5rem;
  margin: 0 auto;
}
.homeSlideshow .slide .contentWrapper .innerContent {
  width: 40%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  opacity: 0;
  -webkit-transition-duration: 500ms;
          transition-duration: 500ms;
}
.homeSlideshow .slide .contentWrapper .innerContent .topContent {
  text-align: left;
  font-weight: 100;
  margin-top: 4em;
}
.homeSlideshow .slide .contentWrapper .innerContent .topContent p {
  font-weight: 100;
}
.homeSlideshow .slide .contentWrapper .innerContent .bottomContent {
  text-align: left;
}
.homeSlideshow .slide .contentWrapper .innerContent .bottomContent h1.branded {
  line-height: 1.1em;
  font-size: 300%;
  text-align: left;
  color: #fff;
}
.homeSlideshow .slide .contentWrapper .innerContent .bottomContent h1.branded:after {
  line-height: 1.5em;
  bottom: 0;
}
.homeSlideshow .slide .contentWrapper .innerContent .bottomContent .summary {
  font-size: 1em;
  font-weight: 100;
}
.homeSlideshow .slide .contentWrapper .innerContent .bottomContent .button {
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 30px;
}
.homeSlideshow .slide.slick-active .innerContent .content {
  opacity: 1;
}
.homeSlideshow .slide.right .contentWrapper .innerContent {
  float: right;
  text-align: right;
}
.homeSlideshow .slide.left .contentWrapper .innerContent {
  float: left;
  text-align: left;
}
.homeSlideshow .slide.centred .coverImg:after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: " ";
  display: block;
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.25);
}
.homeSlideshow .slick-arrow.slick-prev, .homeSlideshow .slick-arrow.slick-next {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  position: absolute;
  background-color: #fff;
  -webkit-transform: none;
          transform: none;
  top: 80%;
  color: #000;
}
.homeSlideshow .slick-arrow.slick-prev:before, .homeSlideshow .slick-arrow.slick-next:before {
  color: #0047BB;
  font-family: "Font Awesome 5 Pro";
  font-size: 18px;
}
.homeSlideshow .slick-arrow.slick-prev {
  left: -webkit-calc(50% - 2.5rem);
  left: calc(50% - 2.5rem);
}
.homeSlideshow .slick-arrow.slick-next {
  left: -webkit-calc(50% + 1rem);
  left: calc(50% + 1rem);
}
.homeSlideshow ul.slick-dots {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  position: absolute;
  bottom: 50%;
  right: 3em;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-align-content: flex-end;
      -ms-flex-line-pack: end;
          align-content: flex-end;
}
.homeSlideshow ul.slick-dots > li {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  width: auto;
}
.homeSlideshow ul.slick-dots li {
  background: none;
  vertical-align: top;
}
.homeSlideshow ul.slick-dots li button:before {
  font-size: 30px;
  color: #fff;
  opacity: 0.7;
}
.homeSlideshow ul.slick-dots li button:hover:before {
  color: #707070;
}
.homeSlideshow ul.slick-dots li.slick-active button:before {
  color: #0047BB;
  opacity: 1;
}

/* #endregion HOME SECTION*/
/* #region GRIDS SECTION*/
.itemGrid .item {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -10px;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  position: relative;
  min-height: 400px;
  /*border: solid 1px #f1f1f1;*/
  /*background-color: $secondaryColour;*/
}
.itemGrid .item > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 10px;
  vertical-align: top;
  /*width: calc((1 / 3) * 100% - 20px);*/
  -webkit-flex-basis: -webkit-calc((1 / 3) * 100% - 20px);
      -ms-flex-preferred-size: calc((1 / 3) * 100% - 20px);
          flex-basis: calc((1 / 3) * 100% - 20px);
}
.itemGrid .item a {
  color: #fff;
}
.itemGrid .item .innerContent {
  color: #fff;
  position: absolute;
  z-index: 5;
  /*top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);*/
}
.itemGrid .item .innerContent h3 {
  /*font-size: 2rem;*/
  margin: 0;
  padding: 0;
  color: #fff;
  text-transform: uppercase;
}
.itemGrid .item .bgImgWrapper {
  z-index: 0;
}

/* #endregion GRIDS SECTION*/
/* #region TEAM SECTION*/
/* #region TEAM LISTING and RELATED TEAM */
.teamgroup h2.branded {
  text-align: center;
}

.students .teammembers > .grid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.students .teammembers > .grid > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 3) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 3) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 3) * 100% - 1rem);
          flex-basis: calc((1 / 3) * 100% - 1rem);
}
@media only screen and (max-width: 1024px) {
  .students .teammembers > .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .students .teammembers > .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 2) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 2) * 100% - 1rem);
            flex-basis: calc((1 / 2) * 100% - 1rem);
  }
}
@media only screen and (max-width: 600px) {
  .students .teammembers > .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .students .teammembers > .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 1) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 1) * 100% - 1rem);
            flex-basis: calc((1 / 1) * 100% - 1rem);
  }
}

.teammembers > .grid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.teammembers > .grid > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 4) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}
.teammembers .contentFilters {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.teammembers .contentFilters .keywordSearchContainer {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.teammembers .contentFilters .buttonsContainer {
  margin: 0 0 0 1em;
}
.teammembers .contentFilters .buttonsContainer .button {
  padding: 0 1em;
}
.teammembers .teammember {
  color: #000000;
  position: relative;
  margin-bottom: 2em;
  font-size: 20px;
}
.teammembers .teammember .bgImgWrapper {
  width: 100%;
  height: 310px;
}
.teammembers .teammember .bgImgWrapper:before {
  /* sets the height and then effectively removes itself from flow so as not to interfere with siblings */
  content: "";
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 100%;
}
.teammembers .teammember .bgImgWrapper:after {
  /* to clear float */
  content: "";
  display: table;
  clear: both;
}
.teammembers .teammember .bgImgWrapper a span.bgImg {
  opacity: 1;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  margin-top: 10%;
}
.teammembers .teammember .content {
  text-align: center;
}
.teammembers .teammember .content h3 {
  margin-bottom: 0;
}
.teammembers .teammember .content h4 {
  position: relative;
  padding-bottom: 0.5em;
  border-bottom: solid 1px #0047BB;
  margin-top: 0.5em;
}
.teammembers .teammember .content h4:after {
  content: "";
  height: 10px;
  width: 20%;
  background-color: #0047BB;
  position: absolute;
  bottom: -10px;
  left: 50%;
  -webkit-transform: translatex(-50%);
          transform: translatex(-50%);
}
.teammembers .teammember .content span.position {
  font-weight: 500;
  font-size: 0.9em;
  display: block;
  margin-bottom: 1em;
}
.teammembers .teammember .content ul.contactInfo {
  margin-top: 1em;
}
.teammembers .teammember .content ul.contactInfo li {
  margin: 0 0 0.25em 0;
}
.teammembers .teammember .content ul.contactInfo li span.prefixLabel {
  padding-right: 0.25em;
}
.teammembers .teammember .content .offices ul li {
  display: inline-block;
  border-right: 1px solid #0047BB;
  padding: 0 0.25em;
}
.teammembers .teammember .content .offices ul li a {
  color: #0047BB;
}
.teammembers .teammember .content .offices ul li:last-of-type {
  border: none;
}
.teammembers .teammember .content ul.socialLinks {
  margin-top: 0.75em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.teammembers .teammember .content ul.socialLinks li {
  padding: 0 0.25em;
}
.teammembers .teammember .content ul.socialLinks li a {
  color: #0047BB;
}
.teammembers .teammember:hover .bgImgWrapper a span.bgImg {
  -webkit-transform: scale(1.35);
          transform: scale(1.35);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
@media only screen and (max-width: 1299px) {
  .teammembers > .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .teammembers > .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 3) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 3) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 3) * 100% - 1rem);
            flex-basis: calc((1 / 3) * 100% - 1rem);
  }
}
@media only screen and (max-width: 1024px) {
  .teammembers > .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .teammembers > .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 2) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 2) * 100% - 1rem);
            flex-basis: calc((1 / 2) * 100% - 1rem);
  }
}
@media only screen and (max-width: 767px) {
  .teammembers > .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .teammembers > .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 2) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 2) * 100% - 1rem);
            flex-basis: calc((1 / 2) * 100% - 1rem);
  }
}
@media only screen and (max-width: 600px) {
  .teammembers > .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .teammembers > .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 1) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 1) * 100% - 1rem);
            flex-basis: calc((1 / 1) * 100% - 1rem);
  }
}

/* #region TEAM BIO PAGE */
.teammember .bannerGrid {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 500px;
  width: 100%;
}
.teammember .bannerGrid .bioTopContent {
  position: absolute;
  z-index: 1;
  width: 32%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  height: 100%;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.teammember .bannerGrid .bioTopContent h1 {
  background-color: #0047BB;
  color: #fff;
  padding: 0.5em 1em;
  margin: 0;
  margin-left: -1em;
  display: inline-block;
  white-space: nowrap;
}
.teammember .bannerGrid .bioTopContent h2 {
  border-bottom: 1px #0047BB solid;
  font-size: 1.2rem;
  color: #0047BB;
  padding-bottom: 0.25em;
  position: relative;
}
.teammember .bannerGrid .bioTopContent h2:before {
  content: "";
  height: 5px;
  width: 50px;
  position: absolute;
  bottom: -5px;
  left: 0;
  background-color: #0047BB;
}
.teammember .bannerGrid .bioTopContent .contactInfo {
  background-color: #E4EAED;
  margin-left: -50vw;
  padding-left: 50vw;
  padding-right: 3em;
}
.teammember .bannerGrid div.bgImgWrapper {
  position: absolute;
  top: 0;
  left: 0;
}
.teammember .topSection {
  padding-top: 2em;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
}
.teammember .topSection .mainContent {
  width: 70%;
  min-width: 70%;
  margin-left: 0rem;
}
.teammember .topSection .mainContent .cmsContent {
  /*> p:nth-of-type(1) {
      font-size: 1.5em;
      line-height: 1.2em;
      margin-bottom: 2rem;
  }*/
}
.teammember .topSection .mainContent .cmsContent h2.branded {
  text-transform: none;
}
.teammember .secondaryContent .credentialsInner ul {
  margin: 0;
}
.teammember .secondaryContent .awardBadges {
  margin-top: 1em;
}
.teammember .secondaryContent .awardBadges img {
  display: block;
  max-width: 60%;
  margin-bottom: 1em;
}

/* #endregion TEAM BIO PAGE */
/*#region SECTORS / EXPERTISE SECTION*/
.sectors > .grid, .expertises > .grid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 1em;
  margin-bottom: 1em;
}
.sectors > .grid > .col, .expertises > .grid > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 4) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}
.sectors > .grid .col, .expertises > .grid .col {
  position: relative;
  padding: 2em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-shadow: 0px 0px 0px 1px #0047BB;
          box-shadow: 0px 0px 0px 1px #0047BB;
  -webkit-transition: -webkit-box-shadow 0.2s linear;
  transition: -webkit-box-shadow 0.2s linear;
  transition: box-shadow 0.2s linear;
  transition: box-shadow 0.2s linear, -webkit-box-shadow 0.2s linear;
  margin-bottom: 1em;
}
.sectors > .grid .col:before, .expertises > .grid .col:before {
  content: "";
  width: 4px;
  height: 30%;
  background-color: #fff;
  top: 5px;
  position: absolute;
  left: -4px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.sectors > .grid .col:after, .expertises > .grid .col:after {
  content: "";
  width: 4px;
  height: 30%;
  background-color: #fff;
  bottom: 5px;
  position: absolute;
  right: -4px;
}
.sectors > .grid .col:hover, .expertises > .grid .col:hover {
  -webkit-box-shadow: 0px 0px 0px 3px #0047BB;
          box-shadow: 0px 0px 0px 3px #0047BB;
}
.sectors > .grid .col h3, .expertises > .grid .col h3 {
  margin: 0;
  padding: 0;
  font-size: 1.1em;
}
.sectors > .grid .col h3:before, .expertises > .grid .col h3:before {
  content: "";
  color: black;
  margin: 0px 10px 0 0;
  font-size: 14px;
  float: left;
}
.sectors > .grid .col h3 span, .expertises > .grid .col h3 span {
  display: block;
  overflow: hidden;
}
@media only screen and (max-width: 1024px) {
  .sectors > .grid, .expertises > .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .sectors > .grid > .col, .expertises > .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 2) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 2) * 100% - 1rem);
            flex-basis: calc((1 / 2) * 100% - 1rem);
  }
  .sectors > .grid .col, .expertises > .grid .col {
    margin-bottom: 1em;
  }
  .sectors > .grid .col h3:before, .expertises > .grid .col h3:before {
    content: none;
  }
}
@media only screen and (max-width: 767px) {
  .sectors > .grid, .expertises > .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .sectors > .grid > .col, .expertises > .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 1) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 1) * 100% - 1rem);
            flex-basis: calc((1 / 1) * 100% - 1rem);
  }
  .sectors > .grid .col, .expertises > .grid .col {
    margin-bottom: 1em;
  }
}

/*#endregion SECTORS SECTION*/
/*#region EXPERTISE / SECTORS SECTION*/
.expertises.accordionUL ul.level1 li:last-child, .sectors.accordionUL ul.level1 li:last-child {
  border-bottom: 1px solid #B1B1B1;
}
.expertises.accordionUL ul.level1 li, .expertises.accordionUL ul.level1 li.hasChildren, .sectors.accordionUL ul.level1 li, .sectors.accordionUL ul.level1 li.hasChildren {
  text-transform: uppercase;
  position: relative;
  padding: 0.75em 1em;
  border-top: 1px solid #B1B1B1;
  font-weight: 700;
  font-size: 0.9rem;
}
.expertises.accordionUL ul.level1 li ul, .expertises.accordionUL ul.level1 li.hasChildren ul, .sectors.accordionUL ul.level1 li ul, .sectors.accordionUL ul.level1 li.hasChildren ul {
  padding-left: 0;
}
.expertises.accordionUL ul.level1 li ul li, .expertises.accordionUL ul.level1 li.hasChildren ul li, .sectors.accordionUL ul.level1 li ul li, .sectors.accordionUL ul.level1 li.hasChildren ul li {
  border: none;
  font-weight: 400;
  padding: 0.25em 0;
}
.expertises.accordionUL ul.level1 li ul li:before, .expertises.accordionUL ul.level1 li.hasChildren ul li:before, .sectors.accordionUL ul.level1 li ul li:before, .sectors.accordionUL ul.level1 li.hasChildren ul li:before {
  top: 2em;
}
.expertises.accordionUL ul.level1 li.hasChildren.active:before, .sectors.accordionUL ul.level1 li.hasChildren.active:before {
  opacity: 0.1;
  background-color: #00778B;
  content: " ";
}
.expertises.accordionUL ul.level1 li.hasChildren.active a, .sectors.accordionUL ul.level1 li.hasChildren.active a {
  position: relative;
  z-index: 2;
}
.expertises.accordionUL ul.level1 > li.hasChildren > ul:before, .sectors.accordionUL ul.level1 > li.hasChildren > ul:before {
  border-bottom: 1px solid #B1B1B1;
  content: " ";
  display: block;
  left: 0;
  height: 1em;
  margin: 0 -1em 0.5em -1em;
}

.expertise.detailView .pageBanner, .sector.detailView .pageBanner {
  min-height: 25rem;
  margin-bottom: 2em;
}
.expertise.detailView .pageBanner .innerContainer, .sector.detailView .pageBanner .innerContainer {
  z-index: 1;
}
.expertise.detailView .cmsContent h2.branded, .sector.detailView .cmsContent h2.branded {
  text-transform: none;
}

.firstParagraphHighlight {
  font-size: 150% !important;
  line-height: 1em;
}

/*#endregion PRACTICE AREA SECTION*/
/*#region RANKINGS SECTION*/
.awardrankings, .rankings {
  text-align: center;
}
.awardrankings > .grid, .rankings > .grid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.awardrankings > .grid > .col, .rankings > .grid > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 5) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 5) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 5) * 100% - 1rem);
          flex-basis: calc((1 / 5) * 100% - 1rem);
}
@media (max-width: 1299px) {
  .awardrankings > .grid, .rankings > .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .awardrankings > .grid > .col, .rankings > .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 4) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
            flex-basis: calc((1 / 4) * 100% - 1rem);
  }
}
@media (max-width: 1050px) {
  .awardrankings > .grid, .rankings > .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .awardrankings > .grid > .col, .rankings > .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 3) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 3) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 3) * 100% - 1rem);
            flex-basis: calc((1 / 3) * 100% - 1rem);
  }
}
@media (max-width: 800px) {
  .awardrankings > .grid, .rankings > .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .awardrankings > .grid > .col, .rankings > .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 2) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 2) * 100% - 1rem);
            flex-basis: calc((1 / 2) * 100% - 1rem);
  }
}
@media (max-width: 550px) {
  .awardrankings > .grid, .rankings > .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .awardrankings > .grid > .col, .rankings > .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 1) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 1) * 100% - 1rem);
            flex-basis: calc((1 / 1) * 100% - 1rem);
  }
}
.awardrankings > .grid > .col, .rankings > .grid > .col {
  margin-bottom: 3em;
}
.awardrankings span, .rankings span {
  display: block;
}
.awardrankings span.yearsAwarded, .rankings span.yearsAwarded {
  display: block;
  padding: 0.25em;
  color: #000;
  font-weight: 700;
}
.awardrankings span.yearsAwarded:before, .rankings span.yearsAwarded:before {
  display: block;
  margin: 0 auto;
  content: " ";
  width: 40%;
  background-color: #0047BB;
  height: 2px;
}
.awardrankings .awardTop, .rankings .awardTop {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 12em;
  padding: 1em;
  margin-bottom: 0.25em;
}
.awardrankings .awardTop:before, .rankings .awardTop:before {
  content: " ";
  background-color: #00778B;
  opacity: 0.1;
}
.awardrankings .awardTop span.awardPublisher, .rankings .awardTop span.awardPublisher {
  color: #000;
  text-transform: uppercase;
  font-weight: 700;
}
.awardrankings .awardTop h4, .rankings .awardTop h4 {
  color: #0047BB;
  font-size: 1.5em;
}
.awardrankings .awardBottom span.awardPublication, .rankings .awardBottom span.awardPublication {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 4em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #003488;
  font-style: italic;
  padding: 0.25em 1em;
}

/*#endregion Rankings SECTION*/
/*#region Featured Content SECTION*/
.featuredcontentgroups {
  font-size: 0.8em;
}
.featuredcontentgroups div.item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.featuredcontentgroups div.item .coverImg {
  min-height: 550px;
}
.featuredcontentgroups div.item .coverImg .bgImgWrapper .bgImg {
  width: 100%;
}
.featuredcontentgroups div.item .contentWrapper {
  width: 50%;
  position: relative;
  padding: 3em;
}
.featuredcontentgroups div.item .contentWrapper > * {
  z-index: 3;
  position: relative;
}
.featuredcontentgroups div.item .contentWrapper:before {
  content: " ";
  position: absolute;
  background-color: #fff;
  opacity: 0.4;
  z-index: 0;
}
.featuredcontentgroups div.item:nth-child(even) .contentWrapper {
  margin-left: auto;
}

/*#endregion Featured Content SECTION*/
/*#region Expandable Lists SECTION*/
.sf-list--expandable {
  font-size: 1em;
}
.sf-list--expandable .sf-list-item a {
  width: 100%;
  display: block;
  margin: 1em 0;
  padding: 1.5em 1.75em;
  position: relative;
  border-bottom: 1px solid #707070;
  font-weight: 700;
}
.sf-list--expandable .sf-list-item a:after {
  content: "+";
  font-size: 1.5em;
  color: #0047BB;
  position: absolute;
  right: 1em;
  top: 0.75em;
}
.sf-list--expandable .sf-list-item a.expanded:after {
  content: "-";
}
.sf-list--expandable .sf-list-item .sf-list-content {
  padding: 1.5em;
  margin-bottom: 1em;
}

/*#endregion Expandable Lists SECTION*/
/*#region TESTIMONIALS SECTION*/
.testimonialsWrapper {
  min-height: initial;
}

.testimonials .testimonial {
  padding: 6em 0;
  text-align: center;
}
.testimonials .testimonial:before {
  display: none;
}
.testimonials .testimonial .content {
  font-size: 1.25em;
  line-height: 1em;
  margin-bottom: 1.4em;
}
.testimonials .testimonial .content p {
  font-size: inherit;
}
.testimonials .testimonial .contact {
  display: block;
  line-height: 1em;
  margin-bottom: 10px;
}
.testimonials .testimonial .company {
  color: #003488;
  font-size: 1.2em;
  font-weight: 700;
}
.testimonials .testimonial .company a {
  color: inherit;
}

ul.testimonials {
  margin: 0;
  padding: 0;
  list-style: none;
}

/*#endregion CLIENTS SECTION*/
/*#region RESOURCES SECTION*/
/* Cases Slider */
.cases.slider .slide {
  color: white;
  overflow: hidden;
  position: relative;
  -webkit-transition: all 1s ease-out;
  transition: all 1s ease-out;
}
.cases.slider .slide .bgImgWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
}
.cases.slider .slide .bgImgWrapper img.bgImg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  max-height: none;
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.cases.slider .slide .bgImgWrapper .bgImg:before {
  position: relative;
  display: block;
  height: 100%;
  content: " ";
  background-image: -webkit-gradient(linear, left top, right top, from(black), color-stop(60%, rgba(63, 108, 118, 0.5)));
  background-image: linear-gradient(to right, black 0%, rgba(63, 108, 118, 0.5) 60%);
  opacity: 0.6;
  z-index: 2;
}
.cases.slider .slide .innerContent {
  width: 50%;
  height: 100%;
  position: relative;
  z-index: 3;
  padding: 10em 0 0 5em;
  text-align: left;
  color: #ffffff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border: none;
}
.cases.slider .slide .innerContent h4 {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  color: #fff;
}
.cases.slider .slide .innerContent h3 {
  margin-top: 0.5em;
  color: #fff;
}
.cases.slider .slide .innerContent h3 a:link, .cases.slider .slide .innerContent h3 a:visited, .cases.slider .slide .innerContent h3 a:active {
  color: #ffffff;
}
.cases.slider .slide .innerContent h3 a:hover, .cases.slider .slide .innerContent h3 a:focus {
  color: #0047BB;
}
.cases.slider button.slick-prev, .cases.slider button.slick-next {
  background-color: #fff;
  opacity: 0.6;
  border-radius: 50%;
  width: 50px;
  color: #000;
}
.cases.slider button.slick-prev:before, .cases.slider button.slick-next:before {
  color: #0047BB;
}

/* List (multi item) Views */
.resources {
  /*div {
      display:flex;
      flex: 0 1 auto;
  }*/
  padding: 1rem 0;
}
.resources > ul.grid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.resources > ul.grid > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 4) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}
@media (max-width: 1024px) {
  .resources > ul.grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .resources > ul.grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 3) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 3) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 3) * 100% - 1rem);
            flex-basis: calc((1 / 3) * 100% - 1rem);
  }
}
@media (max-width: 767px) {
  .resources > ul.grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .resources > ul.grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 1) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 1) * 100% - 1rem);
            flex-basis: calc((1 / 1) * 100% - 1rem);
  }
}
.resources > ul.grid > .col {
  margin-bottom: 2em;
  margin-top: 1em;
}
.resources .slide {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  margin: 0 0.5em;
}
.resources article {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.resources .contentTypeLabels {
  color: #0047BB;
}
.resources .contentTypeLabels a {
  color: #0047BB;
}
.resources .contentTypeLabels a:hover, .resources .contentTypeLabels a:active {
  color: #003488;
  text-decoration: none;
}
.resources .contentTypeLabels .subType {
  display: block;
  text-transform: uppercase;
  color: #0047BB;
  font-size: 0.8rem;
  position: relative;
  margin-bottom: 10px;
}
.resources .contentTypeLabels .subType:before {
  content: "";
  height: 10px;
  width: 55px;
  background-color: #cccccc;
  position: absolute;
  bottom: -10px;
  left: 0;
}
.resources .contentTypeLabels .contentType {
  font-size: 3em;
}
.resources .innerContent {
  position: relative;
  min-height: 300px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.resources .innerContent .flexItem {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.resources .innerContent .flexItem:last-of-type {
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}
.resources .innerContent.noPhoto {
  background-color: #0047BB;
  padding: 1em;
}
.resources .innerContent.noPhoto .itemContent {
  color: #fff;
}
.resources .innerContent.noPhoto .itemContent a {
  color: #fff;
}
.resources .innerContent.noPhoto .itemContent h3 {
  border-bottom: solid 1px #fff;
}
.resources .innerContent .itemContent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.resources .innerContent .itemContent .dates {
  font-size: 0.8em;
  padding: 0.5em 0;
}
.resources .innerContent .itemContent h3 {
  margin: 0 0 0.5em 0;
  padding-bottom: 0.75em;
  color: #fff;
  border-bottom: solid 1px #0047BB;
}
.resources .innerContent .itemContent span.caseCitation {
  display: block;
  margin: 1rem 0 0 0;
  color: #0047BB;
  font-weight: 400;
}
.resources .innerContent .itemContent span.dates {
  display: block;
  margin: 0.5em 0;
  font-weight: 400;
}
.resources .innerContent .itemContent div.summary {
  font-size: 1rem;
  font-weight: 400;
  margin: 0.5em 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.k-ie11 .resources .innerContent .itemContent {
  width: 100%;
}
.resources .innerContent .collapsibleImage {
  width: 100%;
  height: 200px;
  margin-bottom: 1em;
}
.resource.detailView .pageBanner {
  width: 100%;
  min-height: 25em;
  margin-bottom: 2em;
  overflow: hidden;
}
.resource.detailView .pageBanner a {
  color: #fff;
}
.resource.detailView .pageBanner a:hover {
  color: #fff;
}
.resource.detailView .pageBanner .bgImgWrapper {
  position: absolute;
}
.resource.detailView .pageBanner .bgImgWrapper .bgImg:after {
  position: relative;
  display: block;
  height: 100%;
  content: " ";
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.8)), to(rgba(0, 0, 0, 0.2)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2) 100%);
}
.resource.detailView .pageBanner .innerContainer {
  position: relative;
  padding-top: 2em;
  padding-bottom: 2em;
}
.resource.detailView .pageBanner .innerContainer span.caseCitation {
  display: block;
  margin: -1.5em 0 2em 0;
}
.resource.detailView .pageBanner .innerContainer .contentTypeLabels {
  color: white;
}
.resource.detailView .pageBanner .innerContainer .contentTypeLabels .labelValuesSeperator {
  display: none;
}
.resource.detailView .pageBanner .innerContainer .contentTypeLabels .itemSeperator {
  margin-left: 0.5em;
}
.resource.detailView .pageBanner .innerContainer .contentTypeLabels a {
  color: #fff;
}
.resource.detailView .pageBanner .innerContainer .contentTypeLabels a:hover, .resource.detailView .pageBanner .innerContainer .contentTypeLabels a:active {
  color: #fff;
  text-decoration: none;
}
.resource.detailView .pageBanner .innerContainer .contentTypeLabels .contentType,
.resource.detailView .pageBanner .innerContainer .contentTypeLabels .subType {
  font-weight: 700;
  text-transform: uppercase;
}
.resource.detailView .pageBanner .innerContainer .contentTypeLabels .contentType {
  font-size: 1em;
}
.resource.detailView .pageBanner .innerContainer .contentTypeLabels .subType {
  font-size: 1em;
}
.resource.detailView .pageBanner .innerContainer .related {
  margin: 0.5em 0;
}
.resource.detailView .pageBanner .innerContainer .related ul.grid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  font-size: 1em;
  padding-bottom: 1em;
}
.resource.detailView .pageBanner .innerContainer .related ul.grid > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  width: auto;
}
.resource.detailView .pageBanner .innerContainer .related ul.grid li a:after {
  content: "|";
  padding-left: 0.5em;
}
.resource.detailView .pageBanner .innerContainer .related ul.grid li:last-child a:after {
  content: "";
}
.resource.detailView .pageBanner .innerContainer .authorTeam {
  margin-top: 1.5em;
}
.resource.detailView .pageBanner .innerContainer span.dates {
  display: block;
  margin: 0.5em 0;
}
.resource.detailView .pageBanner .innerContainer ul.sharingLinks li.sharingLink button i {
  color: #fff;
}
.resource.detailView .mainContent {
  padding-top: 2em;
}
.resource.detailView .secondaryContent {
  -webkit-flex-shrink: 1;
      -ms-flex-negative: 1;
          flex-shrink: 1;
}

.resourcesSlider .slick-track {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.resourcesSlider ul.slick-dots {
  display: none !important;
}
.resourcesSlider .item.slide {
  width: 33%;
  margin: 0;
  -webkit-box-shadow: 0 0 36px rgba(94, 106, 113, 0.2);
          box-shadow: 0 0 36px rgba(94, 106, 113, 0.2);
  border: 1px solid #dbdbdd;
  vertical-align: top;
  height: auto !important;
}

.filters {
  padding: 3em 0;
}
.filters form .filterOptions {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.filters form .filterOptions > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  width: auto;
}
.filters form .filterOptions fieldset {
  border: none;
  padding: 0;
  font-size: 1rem;
  position: relative;
}
.filters form .filterOptions fieldset > h3,
.filters form .filterOptions fieldset > label {
  color: #0047BB;
  margin: 0;
  display: block;
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 500;
  text-transform: uppercase;
}
.filters form .filterOptions[data-f-display-type=checkboxes] fieldset {
  width: 100%;
  /*border: 1px solid tomato;*/
}
.filters form .filterOptions[data-f-display-type=checkboxes] .fields {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.filters form .filterOptions[data-f-display-type=checkboxes] .fields > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 3) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 3) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 3) * 100% - 1rem);
          flex-basis: calc((1 / 3) * 100% - 1rem);
}
.filters form .filterOptions[data-f-display-type=checkboxes] .fields .field {
  padding: 0.2em 0;
  margin-bottom: 0;
}
.filters form .filterOptions[data-f-display-type=dropdowns] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.filters form .filterOptions[data-f-display-type=dropdowns] > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 4) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}
@media (max-width: 767px) {
  .filters form .filterOptions[data-f-display-type=dropdowns] {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .filters form .filterOptions[data-f-display-type=dropdowns] > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 2) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 2) * 100% - 1rem);
            flex-basis: calc((1 / 2) * 100% - 1rem);
  }
}
@media (max-width: 600px) {
  .filters form .filterOptions[data-f-display-type=dropdowns] {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .filters form .filterOptions[data-f-display-type=dropdowns] > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 1) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 1) * 100% - 1rem);
            flex-basis: calc((1 / 1) * 100% - 1rem);
  }
}
.filters form .filterOptions[data-f-display-type=dropdowns] .col {
  /*min-width: 280px;*/
}
.filters form .filterOptions[data-f-display-type=dropdowns] fieldset {
  /*border: 1px solid lime;*/
}
.filters h3 {
  text-transform: uppercase;
  font-size: 1em;
  margin-top: 1em;
}
.filters button {
  width: auto;
  height: 2.6em;
  min-width: initial;
  margin-top: 1.2rem;
}
.filters button[type=reset] {
  width: 100%;
}
.filters input[type=checkbox] {
  cursor: pointer;
  width: 1px;
  height: 1px;
  position: absolute;
  visibility: hidden;
}
.filters input[type=checkbox] + label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 1em;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.filters input[type=checkbox] + label::-moz-selection {
  background-color: transparent;
}
.filters input[type=checkbox] + label::selection {
  background-color: transparent;
}
.filters input[type=checkbox] + label:hover {
  color: black;
}
.filters input[type=checkbox] + label:before, .filters input[type=checkbox] + label:after {
  top: 0;
  left: 0;
  display: inline-block;
  position: absolute;
  font-size: 1rem;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  margin-right: 0.4em;
}
.filters input[type=checkbox] + label:before {
  content: "";
  color: black;
}
.filters input[type=checkbox] + label:after {
  top: 2px;
  left: 1px;
  font-size: 0.8em;
  position: absolute;
  content: "";
  color: #003488;
  -webkit-transform: scale(0);
          transform: scale(0);
  text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
}
.filters input[type=checkbox]:checked + label:after {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.filters .keywordSearch {
  border: 1px solid black;
}
.filters input[type=text] {
  width: 100%;
  /*max-width: 300px;*/
  color: #0047BB;
  padding: 0.2em 1em;
  padding-right: 3em;
  height: 2.6em;
  background-image: url(/CFImages/Icons/search-pipe.svg);
  background-repeat: no-repeat;
  background-position: right 1em center;
  text-overflow: ellipsis;
}
.filters input[type=text]::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.filters input[type=text]:-moz-placeholder {
  opacity: 1;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.filters input[type=text]::-moz-placeholder {
  opacity: 1;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.filters input[type=text]:-ms-input-placeholder {
  opacity: 1;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.filters input[type=text]:hover::-webkit-input-placeholder, .filters input[type=text]:focus::-webkit-input-placeholder {
  opacity: 0.4;
}
.filters input[type=text]:hover:-moz-placeholder, .filters input[type=text]:focus:-moz-placeholder {
  opacity: 0.4;
}
.filters input[type=text]:hover::-moz-placeholder, .filters input[type=text]:focus::-moz-placeholder {
  opacity: 0.4;
}
.filters input[type=text]:hover:-ms-input-placeholder, .filters input[type=text]:focus:-ms-input-placeholder {
  opacity: 0.4;
}
.filters input[type=text]:focus {
  outline: 2px solid black;
}
.filters select {
  width: 100%;
  /*width: 280px;*/
  color: #0047BB;
  cursor: pointer;
  border: 1px solid black;
  padding: 0.2em 1em;
  font-size: 1rem;
  line-height: 1;
  font-family: "canada-type-gibson", Arial, sans-serif;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  background-image: url(/CFImages/Icons/triangle-down-purple.svg);
  background-repeat: no-repeat;
  background-position: right 1em center;
  background-size: 0.6em auto;
  padding-right: 1.6em;
  height: 2.6em;
  text-transform: uppercase;
}
.filters select:focus {
  outline: none;
  outline: 2px solid black;
}
.filters select > option {
  text-transform: none;
}
.filters select > option:checked, .filters select > option:hover {
  background-color: #E4EAED;
}
.filters #currentFilters {
  display: none;
  padding: 20px 30px;
  margin: 0 0 10px 0;
  border: 1px solid #ccc;
  background-color: #fff;
}
.filters #currentFilters.showThis {
  display: inline-block;
  width: 48%;
  vertical-align: top;
}
.filters #currentFilters div.filterDataGroup {
  margin-bottom: 10px;
}
.filters #currentFilters div.filterDataGroup span.removeFilter {
  position: relative;
  display: inline-block;
  border: 1px solid #ccc;
  padding: 10px 30px 10px 10px;
  margin-left: 10px;
}
.filters #currentFilters div.filterDataGroup span.removeFilter:hover {
  cursor: pointer;
  background-color: red;
  color: #fff;
}
.filters #currentFilters div.filterDataGroup span.removeFilter:after {
  content: "x";
  position: absolute;
  right: 10px;
}

.mixContainer .metaDisplayForTesting {
  display: none !important;
}
.mixContainer button {
  min-width: initial;
}
.mixContainer span.metaDisplayForTesting {
  display: block;
  padding: 20px 30px;
  margin: 0 0 10px 0;
  border: 1px solid #ccc;
  background-color: #eee;
}

.mixitup-page-list {
  margin-top: 1rem;
  text-align: center;
}
.mixitup-page-list button {
  min-width: initial;
}
.mixitup-page-list .mixitup-control {
  margin: 0;
}
.mixitup-page-list .mixitup-control.mixitup-control-prev, .mixitup-page-list .mixitup-control.mixitup-control-next {
  color: transparent !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1em;
}
.mixitup-page-list .mixitup-control.mixitup-control-prev {
  background-image: url(/CFImages/Icons/arrow-left-teal.svg);
}
.mixitup-page-list .mixitup-control.mixitup-control-next {
  background-image: url(/CFImages/Icons/arrow-right-teal.svg);
}
.mixitup-page-list .mixitup-control.mixitup-control-disabled {
  /*opacity: 0.4;*/
}
.mixitup-page-list .mixitup-control.mixitup-control-active {
  color: black !important;
}

.mixitup-page-stats {
  color: #0047BB;
  text-align: center;
  font-family: "canada-type-gibson", Arial, sans-serif;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

/*#endregion RESOURCES SECTION*/
/*#region FACTS / FACT GROUPS */
.factgroups {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
  margin: 1.5em 0;
}
.factgroups .factGroupOuter {
  position: relative;
  padding: 4em 1em;
}
.factgroups .bgImgWrapper .bgImg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.factgroups .facts {
  top: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  z-index: 1;
  position: relative;
  text-align: center;
  overflow: hidden;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0;
}
.factgroups .facts .fact {
  color: black;
  width: 16em;
  margin: 2em auto;
  display: inline-block;
  position: relative;
  border: 1px solid #B1B1B1;
  background-color: #fff;
  border-radius: 50%;
}
.factgroups .facts .fact {
  font-size: 11px;
}
@media screen and (min-width: 767px) {
  .factgroups .facts .fact {
    font-size: -webkit-calc(11px + 9 * ((100vw - 767px) / 532));
    font-size: calc(11px + 9 * ((100vw - 767px) / 532));
  }
}
@media screen and (min-width: 1299px) {
  .factgroups .facts .fact {
    font-size: 20px;
  }
}
.factgroups .facts .fact:before {
  /* sets the height and then effectively removes itself from flow so as not to interfere with siblings */
  content: "";
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 100%;
}
.factgroups .facts .fact:after {
  /* to clear float */
  content: "";
  display: table;
  clear: both;
}
.factgroups .facts .fact .factInner {
  top: 50%;
  left: 0;
  right: 0;
  padding: 1.2em;
  max-width: 100%;
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.factgroups .facts .fact .factInner .factInnerTop {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 0;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.factgroups .facts .fact .factInner .factInnerTop > span {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0;
  vertical-align: top;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  width: auto;
}
.factgroups .facts .fact .factInner .factInnerTop .value, .factgroups .facts .fact .factInner .factInnerTop .units {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.factgroups .facts .fact .factInner .factInnerTop .value {
  font-size: 4em;
  line-height: 1.2em;
  font-weight: 700;
}
.factgroups .facts .fact .factInner .factInnerTop .units {
  font-size: 2em;
  line-height: 1em;
  font-weight: 300;
  text-transform: uppercase;
}
.factgroups .facts .fact .factInner .factInnerTop:after {
  content: "";
  height: 0.5em;
  width: 2em;
  background-color: #0047BB;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  bottom: 0;
}
.factgroups .facts .fact .factInner .factInnerBottom {
  position: relative;
  padding: 1em 0.3em 0;
}
.factgroups .facts .fact .factInner .factInnerBottom .subject {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.1em;
  text-transform: uppercase;
  white-space: pre-wrap;
}

/*#endregion FACTS / FACT GROUPS */
/*#region CONTACT SECTION*/
.quickContact p {
  margin: 12px 0;
}
.quickContact p span.required {
  color: #cc0000;
  margin-top: 8px;
  width: 100%;
  font-size: 12px;
  line-height: 1em;
  margin-bottom: 5px !important;
}
.quickContact p span.required:last-of-type {
  margin-bottom: 20px !important;
}
.quickContact input, .quickContact textarea, .quickContact select {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  padding: 13px 10px 11px !important;
  font-style: normal !important;
  color: black !important;
  font-size: 18px !important;
  font-family: "canada-type-gibson", Arial, sans-serif !important;
  font-weight: 400 !important;
  -webkit-box-sizing: border-box !important;
          box-sizing: border-box !important;
  height: auto !important;
  border: 1px solid grey !important;
  width: 100%;
  letter-spacing: 0.02em;
}
.quickContact .RadInput {
  width: 100% !important;
}
.quickContact select {
  width: -webkit-calc(100%);
  width: calc(100%);
  padding: 12px 8px !important;
}
.quickContact textarea {
  padding: 12px 10px 20px !important;
  min-height: 100px;
}
.quickContact .fieldLabel {
  color: white;
  width: 100%;
  font-size: 12px;
  color: grey;
  margin-bottom: 1px !important;
  letter-spacing: 0.05em;
  display: block;
  text-transform: uppercase;
}
.quickContact .fieldLabel em {
  float: right;
  font-size: 10px;
}
.quickContact .submit .button {
  margin-top: 15px;
}

/*#endregion CONTACT SECTION*/
/*#region WRAPPERS*/
.plainWrapper, .sectorsWrapper, .projectsWrapper, .resourcesWrapper {
  min-height: 400px;
  padding: 2em 0;
}

.plainWrapper {
  text-align: center;
}

.blueWrapper {
  background-color: #E6F6FA;
}

.sectorsWrapper {
  color: white;
  text-align: right;
  background-color: #0047BB;
  padding-top: 60px;
  padding-bottom: 140px;
}
.sectorsWrapper .sectorsRight {
  position: relative;
  /*padding-bottom: 60px;*/
}
.sectorsWrapper .sectorsRight .button {
  bottom: 0;
  right: 0;
  position: absolute;
}
.sectorsWrapper .sectorsImage img {
  max-width: 100%;
}
.sectorsWrapper h2, .sectorsWrapper h3, .sectorsWrapper h4, .sectorsWrapper p {
  color: #fff;
}
.sectorsWrapper h3 {
  font-size: 5em;
  font-weight: 700;
  line-height: 0.9;
  /*max-width: 100vw;*/
}
@media (min-width: 1025px) {
  .sectorsWrapper h3 {
    margin-left: -100%;
  }
}
.sectorsWrapper h4 {
  font-weight: 400;
  font-size: 30px;
  line-height: 0.8em;
  margin-bottom: 40px;
}

.greyWrapper {
  padding: 4em 0;
  background-color: #F1F1F1;
}

/*#endregion WRAPPERS*/
/* #region PROJECTS SECTION*/
.projects.related .carousel .slick-list {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -1rem;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.projects.related .carousel .slick-list > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 1rem;
  vertical-align: top;
  /*width: calc((1 / 3) * 100% - 2rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 3) * 100% - 2rem);
      -ms-flex-preferred-size: calc((1 / 3) * 100% - 2rem);
          flex-basis: calc((1 / 3) * 100% - 2rem);
}
.projects.related .carousel .slide {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 1rem;
  vertical-align: top;
  /*width: calc((1 / 3) * 100% - 2rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 3) * 100% - 2rem);
      -ms-flex-preferred-size: calc((1 / 3) * 100% - 2rem);
          flex-basis: calc((1 / 3) * 100% - 2rem);
}

article.projectCard {
  width: 100%;
  position: relative;
  background-color: white;
  -webkit-box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.16);
          box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.16);
}
article.projectCard:before {
  /* sets the height and then effectively removes itself from flow so as not to interfere with siblings */
  content: "";
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 100%;
}
article.projectCard:after {
  /* to clear float */
  content: "";
  display: table;
  clear: both;
}
/*#region RELATED CONTENT SECTION*/
.itemGrid2 {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -10px;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.itemGrid2 > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 10px;
  vertical-align: top;
  /*width: calc((1 / 2) * 100% - 20px);*/
  -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 20px);
      -ms-flex-preferred-size: calc((1 / 2) * 100% - 20px);
          flex-basis: calc((1 / 2) * 100% - 20px);
}

.carousel {
  margin: 0;
  padding: 0;
}
.carousel li {
  margin: 0 3px;
  padding: 0;
}
.carousel li:before {
  content: "" !important;
  margin: 0 !important;
}

.slider .slide {
  min-height: 500px;
}

/*#endregion RELATED CONTENT SECTION*/
.socialLinks em, .socialLinks i, .sharingLinks em, .sharingLinks i, .searchBox em, .searchBox i {
  font-style: normal !important;
}

.viewMoreOuter {
  margin: 0em auto 3em;
  text-align: center;
}

.mainContent {
  padding-right: 3em;
}

/*#region toggle Accordion SECTION*/
.accordionItem .toggleAction {
  position: relative;
  padding: 1em 2em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-shadow: 0px 0px 0px 1px #0047BB;
          box-shadow: 0px 0px 0px 1px #0047BB;
  -webkit-transition: -webkit-box-shadow 0.2s linear;
  transition: -webkit-box-shadow 0.2s linear;
  transition: box-shadow 0.2s linear;
  transition: box-shadow 0.2s linear, -webkit-box-shadow 0.2s linear;
  margin-bottom: 1em;
  /*&:after {
      content: "";
      width: 4px;
      height: 30%;
      background-color: #fff;
      bottom: 5px;
      position: absolute;
      right: -4px;
  }*/
}
.accordionItem .toggleAction:before {
  content: "";
  width: 4px;
  height: 30%;
  background-color: #fff;
  top: 5px;
  position: absolute;
  left: -4px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.accordionItem .toggleAction:hover {
  -webkit-box-shadow: 0px 0px 0px 3px #0047BB;
          box-shadow: 0px 0px 0px 3px #0047BB;
}
.accordionItem .toggleAction h3 {
  margin: 0;
}
.accordionItem .toggleAction:after {
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-size: 1em;
  position: absolute;
  top: 50%;
  -webkit-transform: translatey(-50%);
          transform: translatey(-50%);
  right: 1em;
}
.accordionItem .toggleContent {
  display: none;
}
.accordionItem .toggleContent ul {
  margin-top: 0;
}
.accordionItem .toggleContent .viewMoreOuter .button {
  margin: 0;
}
.accordionItem.active .toggleAction:after {
  content: "";
}

/*#endregion Toggle Accordion SECTION*/
/*#region Filtered Content Items SECTION*/
.filteredContent .filtersContainer {
  width: 100%;
  display: block;
  margin: 0 auto;
  margin: 2rem auto;
}
.filteredContent .searchByInitialContainer {
  margin: 2rem 0;
}
.filteredContent .searchByInitialContainer > .grid > .col a.initialFilter {
  cursor: pointer;
  text-decoration: none;
}
.filteredContent .searchByInitialContainer > .grid > .col a.initialFilter.selected {
  color: #0047BB;
}
.filteredContent .buttonsContainer {
  margin-top: 1em;
}
.filteredContent .buttonsContainer .button i {
  margin-left: 2rem;
}
.filteredContent .buttonsContainer .button:not(:last-child) {
  margin-right: 1rem;
}
.filteredContent .loadMoreContainer {
  text-align: center;
  margin: 0;
}
.filteredContent .loadMoreContainer button i {
  margin-right: 0.4rem;
}

.advancedFiltersContainer {
  background-color: white;
  width: 100%;
  height: auto;
  display: block;
  position: relative;
  margin-left: 0;
}
.advancedFiltersContainer .dynamicFiltersContainer.column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.advancedFiltersContainer .dynamicFiltersContainer.column > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 4) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}
.advancedFiltersContainer .dynamicFiltersContainer.column .toggleAction {
  display: none;
}
.advancedFiltersContainer .closeFiltersButton {
  background-color: #0047BB;
  background-image: none;
  border: none;
  color: black;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 1.5rem;
  right: 3rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
}
.advancedFiltersContainer .closeFiltersButton .fa-times {
  color: white;
}
.advancedFiltersContainer .closeFiltersButton:hover, .advancedFiltersContainer .closeFiltersButton:focus, .advancedFiltersContainer .closeFiltersButton:focus-within, .advancedFiltersContainer .closeFiltersButton:active {
  background-color: black;
}
.k-ie11 .advancedFiltersContainer .toggleContent select::-ms-expand {
  display: none;
}

#filtersDisplayContainer {
  display: none;
  margin: 1rem 0;
}
#filtersDisplayContainer .activeFilterGroup {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
#filtersDisplayContainer .activeFilterGroup .activeFilterTag {
  border: 1px solid #E4EAED;
  padding: 0.4rem 1rem;
  cursor: pointer;
  position: relative;
  padding-right: 2rem;
  margin-bottom: 0.6rem;
}
#filtersDisplayContainer .activeFilterGroup .activeFilterTag:not(:last-of-type) {
  margin-right: 0.6rem;
}
#filtersDisplayContainer .activeFilterGroup .activeFilterTag:after {
  content: "x";
  right: 1rem;
  color: #0047BB;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.filtersPopupWrapper {
  width: 100%;
  left: 0 !important;
}
.filtersPopupWrapper .advancedFiltersContainer {
  margin: 0 auto;
  display: block !important;
}

#loadingIndicator {
  height: 200px;
  display: none;
}
#loadingIndicator svg {
  margin: 0 auto;
}
#loadingIndicator.loading {
  content: "loading";
  line-height: 200px;
  text-align: center;
  display: block;
}

#loadingCounter {
  margin: 2rem 0;
}

input::-webkit-input-placeholder {
  color: #B1B1B1;
  font-size: 0.8em;
}

input::-moz-placeholder {
  color: #B1B1B1;
  font-size: 0.8em;
}

input:-ms-input-placeholder {
  color: #B1B1B1;
  font-size: 0.8em;
}

input::-ms-input-placeholder {
  color: #B1B1B1;
  font-size: 0.8em;
}

input::placeholder {
  color: #B1B1B1;
  font-size: 0.8em;
}