/* ### Font Awesome Icons ###*/
/* ### Navigational Icons */
/* ### Other Icons */
/* XS Mobile (max width) */
/* Mobile (min width) */
/* Mobile (max width) */
/* Tablets (min width) */
/* Tablets (max width) */
/* Small Desktop  (min width) */
/* Small Desktop (max width) */
/* Large Desktop (min width) - THIS IS THE DEFAULT STYLES */
/* Large Desktop Breakpoint where we need to apply global innerWrapper Padding */
/*Set maximum viewable area of the site */
/* ### Font Awesome Icons ###*/
/* ### Navigational Icons */
/* ### Other Icons */
/* XS Mobile (max width) */
/* Mobile (min width) */
/* Mobile (max width) */
/* Tablets (min width) */
/* Tablets (max width) */
/* Small Desktop  (min width) */
/* Small Desktop (max width) */
/* Large Desktop (min width) - THIS IS THE DEFAULT STYLES */
/* Large Desktop Breakpoint where we need to apply global innerWrapper Padding */
/*Set maximum viewable area of the site */
/*@if(type-of($Columns) == list) {
    // loop through values and assign to columns
    @each $col in $Columns {
    }
}*/
.newGrid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.newGrid > .col {
  /*flex: 1 0 auto;*/
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  /*order: 0;*/
  min-width: 0;
  max-width: 100%;
  padding: 0 0.5rem;
  vertical-align: top;
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}

/* ### REM FALLBACK ### */
/*---------------   
--Mixin: overlay
----------------- */
/* Old Font Size Function
@mixin font-size($sizeValue, $line: 1.5){
  font-size: ($sizeValue) + px;
  line-height: ($line * $sizeValue) + px;
  font-size: ($sizeValue / 10) *.625 + rem;
  line-height: (($line * $sizeValue) / 10) *.625 + rem;
}
*/
/*
$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 24px;

@include fluid-type($min_width, $max_width, $min_font, $max_font);

*/
/* ### Font Awesome Icons ###*/
/* ### Navigational Icons */
/* ### Other Icons */
/* XS Mobile (max width) */
/* Mobile (min width) */
/* Mobile (max width) */
/* Tablets (min width) */
/* Tablets (max width) */
/* Small Desktop  (min width) */
/* Small Desktop (max width) */
/* Large Desktop (min width) - THIS IS THE DEFAULT STYLES */
/* Large Desktop Breakpoint where we need to apply global innerWrapper Padding */
/*Set maximum viewable area of the site */
/*@if(type-of($Columns) == list) {
    // loop through values and assign to columns
    @each $col in $Columns {
    }
}*/
.newGrid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.newGrid > .col {
  /*flex: 1 0 auto;*/
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  /*order: 0;*/
  min-width: 0;
  max-width: 100%;
  padding: 0 0.5rem;
  vertical-align: top;
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}

/* ### REM FALLBACK ### */
/*---------------   
--Mixin: overlay
----------------- */
/* Old Font Size Function
@mixin font-size($sizeValue, $line: 1.5){
  font-size: ($sizeValue) + px;
  line-height: ($line * $sizeValue) + px;
  font-size: ($sizeValue / 10) *.625 + rem;
  line-height: (($line * $sizeValue) / 10) *.625 + rem;
}
*/
/*
$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 24px;

@include fluid-type($min_width, $max_width, $min_font, $max_font);

*/
/* Force text to wrap with hyphen if to long (example long email) */
/*
    Placeholder usage:

    .className {
        @extend %placeHoldername;
    }
*/
/* 
Media queries for tablet and mobile (general guidelines for breakpoints globally set in the _variables file) 
Mobile ($S_Max)   Tablet ($M_Min and $M_Max)  Small Desktop ($L_Min and $L_Max)  Large Desktop ($XL_Min - This is the standard for all styles)
*/
/* Small Desktop (switch between simple and mobile nav) */
@media only screen and (max-width: 1299px) {
  .aboutWrapper .left {
    -webkit-flex-basis: -webkit-calc(45% - 4rem);
        -ms-flex-preferred-size: calc(45% - 4rem);
            flex-basis: calc(45% - 4rem);
  }

  .teammember .bannerGrid .bioTopContent {
    width: 40%;
  }
  .teammember .bannerGrid .bioTopContent h1 {
    margin: 0;
    font-size: 2.5rem;
  }
  .teammember .bannerGrid .bioTopContent .contactInfo {
    margin-left: -3em;
    padding-left: 6em;
  }
  .teammember .bannerGrid .bgImgWrapper .bgImg {
    background-position: 80%;
  }
}
/* Tablet */
@media only screen and (max-width: 1024px) {
  .aboutWrapper .left {
    -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
    padding: 2em;
  }

  .innerContainer {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  button.hamburger {
    display: block;
  }

  nav ul.mainNav {
    display: none !important;
  }

  header .header .grid.topHeaderGrid .search {
    width: inherit !important;
  }
  header .header .grid.topHeaderGrid .search .ui-autocomplete-input {
    width: 240px;
    top: -2px;
    position: relative;
  }
  header .header .grid.topHeaderGrid .social {
    width: inherit !important;
  }

  #wrapper {
    width: 100%;
  }

  footer .preFooter .innerContainer .preFooterGrid .sitemap .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  footer .preFooter .innerContainer .preFooterGrid .sitemap .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 2) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 2) * 100% - 1rem);
            flex-basis: calc((1 / 2) * 100% - 1rem);
  }
  footer .preFooter .innerContainer .preFooterGrid .social {
    margin-top: 1em;
  }
  footer .mainFooter .footerGrid .copyright, footer .mainFooter .footerGrid .privacy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
  }

  main .bd {
    /*.mainContent {
        padding-right: initial;
    }*/
  }
  main .bd .secondaryContent {
    margin-top: 0 !important;
  }

  /* #region TEAM BIO PAGE */
  .teammember .bannerGrid .bioTopContent h1 {
    font-size: 2rem;
    margin-left: -1em;
  }
  .teammember .bannerGrid .bioTopContent .contactInfo {
    margin-left: -2em;
    padding-left: 2em;
  }
  .teammember .bannerGrid .bgImgWrapper .bgImg {
    background-position: 74%;
  }
  .teammember .topSection {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .teammember .topSection > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 1) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 1) * 100% - 1rem);
            flex-basis: calc((1 / 1) * 100% - 1rem);
  }
  .teammember .topSection .mainContent {
    width: 100%;
    min-width: 100%;
    padding-right: 1em;
  }
  .teammember .topSection .secondaryContent {
    width: 100%;
    min-width: 100%;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -".credentials"/2;
    flex-wrap: wrap;
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    margin: 0 -"aside"/2;
    flex-wrap: wrap;
  }
  .teammember .topSection .secondaryContent > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 ".credentials"/2;
    vertical-align: top;
    /*width: calc((1 / 1) * 100% - .credentials);*/
    -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - .credentials);
        -ms-flex-preferred-size: calc((1 / 1) * 100% - .credentials);
            flex-basis: calc((1 / 1) * 100% - .credentials);
  }
  .teammember .topSection .secondaryContent > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 "aside"/2;
    vertical-align: top;
    /*width: calc((1 / 2) * 100% - aside);*/
    -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - aside);
        -ms-flex-preferred-size: calc((1 / 2) * 100% - aside);
            flex-basis: calc((1 / 2) * 100% - aside);
  }
  .teammember .topSection .secondaryContent section, .teammember .topSection .secondaryContent aside {
    margin-top: 0em;
  }
  .teammember .topSection .secondaryContent .credentials {
    margin-bottom: 2rem;
  }
  .teammember .secondaryContent .awardBadges {
    margin-top: 1em;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .teammember .secondaryContent .awardBadges img {
    max-width: 100%;
    margin: 1em;
  }

  /* #endregion TEAM BIO PAGE */
}
/* Mobile Only */
@media only screen and (max-width: 767px) {
  header .header .grid.topHeaderGrid {
    -webkit-align-content: flex-start;
        -ms-flex-line-pack: start;
            align-content: flex-start;
  }
  header .header .grid.topHeaderGrid .search {
    width: 100% !important;
  }
  header .header .grid.topHeaderGrid .search .ui-autocomplete-input {
    width: -webkit-calc(100% - 62px);
    width: calc(100% - 62px);
    top: -2px;
    position: relative;
  }
  header .header .grid.topHeaderGrid .search button {
    height: 33px;
    font-size: 16px;
  }
  header .header .grid.topHeaderGrid .search button:after {
    left: 4px;
    top: 10px;
  }
  header .header .grid.topHeaderGrid .social {
    padding-right: 0.75em;
  }
  header .header .grid.topHeaderGrid ul.socialLinks {
    margin-right: 0;
  }
  header .header .grid.topHeaderGrid ul.socialLinks li {
    margin: 0 0 0 0.1em;
  }
  header .header .grid.topHeaderGrid ul.socialLinks li em {
    font-size: 22px;
  }

  footer .mainFooter .logo {
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 1em;
  }
  footer .mainFooter .contact {
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    padding-bottom: 1em;
    text-align: center;
  }
  footer .mainFooter .subscribe {
    padding-bottom: 2em;
  }
  footer .mainFooter .social {
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    padding-bottom: 1em;
  }

  .awardsWrapper img {
    width: 100%;
  }

  .teammembers .teammember .bgImgWrapper {
    height: 480px;
  }

  .teammember .bannerGrid {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    height: inherit;
  }
  .teammember .bannerGrid .innerContainer {
    padding: 0;
  }
  .teammember .bannerGrid .bioTopContent {
    width: 100%;
    position: inherit;
  }
  .teammember .bannerGrid .bioTopContent h1 {
    margin: 0;
    display: block;
  }
  .teammember .bannerGrid .bioTopContent .contactInfo {
    margin-left: 0;
    padding: 1em 2em 2em 2em;
  }
  .teammember .bannerGrid div.bgImgWrapper {
    position: inherit;
    height: 400px;
  }
  .teammember .bannerGrid div.bgImgWrapper .bgImg {
    background-position: -webkit-calc( 100% + 26vw);
    background-position: calc( 100% + 26vw);
    height: 400px;
  }
}
/* XS Mobile Only */
@media only screen and (max-width: 600px) {
  .teammember .bannerGrid div.bgImgWrapper .bgImg {
    background-position: 81%;
  }
  .teammember .bannerGrid .bioTopContent h1 {
    white-space: normal;
  }

  .pageBanner .bannerImage .bgImg {
    background-attachment: initial;
  }
  .pageBanner .bannerTitle h1 {
    font-size: 9vw !important;
    width: 100%;
    padding: 1em;
    margin-left: 0;
    max-width: inherit;
  }
  .pageBanner .bannerTitle .summary {
    width: 100% !important;
  }

  .slider .slide {
    min-height: inherit;
  }

  footer .bottomFooter .siteMapWrapper ul {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .homeSlideshow .slide .pageBanner .bannerTitle {
    font-size: 2rem;
  }
  .homeSlideshow .slide .pageBanner .summary {
    display: none;
  }
}