@charset "UTF-8";
/* ### Font Awesome Icons ###*/
/* ### Navigational Icons */
/* ### Other Icons */
/* XS Mobile (max width) */
/* Mobile (min width) */
/* Mobile (max width) */
/* Tablets (min width) */
/* Tablets (max width) */
/* Small Desktop  (min width) */
/* Small Desktop (max width) */
/* Large Desktop (min width) - THIS IS THE DEFAULT STYLES */
/* Large Desktop Breakpoint where we need to apply global innerWrapper Padding */
/*Set maximum viewable area of the site */
/* ### Font Awesome Icons ###*/
/* ### Navigational Icons */
/* ### Other Icons */
/* XS Mobile (max width) */
/* Mobile (min width) */
/* Mobile (max width) */
/* Tablets (min width) */
/* Tablets (max width) */
/* Small Desktop  (min width) */
/* Small Desktop (max width) */
/* Large Desktop (min width) - THIS IS THE DEFAULT STYLES */
/* Large Desktop Breakpoint where we need to apply global innerWrapper Padding */
/*Set maximum viewable area of the site */
/*@if(type-of($Columns) == list) {
    // loop through values and assign to columns
    @each $col in $Columns {
    }
}*/
.newGrid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.newGrid > .col {
  /*flex: 1 0 auto;*/
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  /*order: 0;*/
  min-width: 0;
  max-width: 100%;
  padding: 0 0.5rem;
  vertical-align: top;
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}

/* ### REM FALLBACK ### */
/*---------------   
--Mixin: overlay
----------------- */
/* Old Font Size Function
@mixin font-size($sizeValue, $line: 1.5){
  font-size: ($sizeValue) + px;
  line-height: ($line * $sizeValue) + px;
  font-size: ($sizeValue / 10) *.625 + rem;
  line-height: (($line * $sizeValue) / 10) *.625 + rem;
}
*/
/*
$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 24px;

@include fluid-type($min_width, $max_width, $min_font, $max_font);

*/
/* ### Font Awesome Icons ###*/
/* ### Navigational Icons */
/* ### Other Icons */
/* XS Mobile (max width) */
/* Mobile (min width) */
/* Mobile (max width) */
/* Tablets (min width) */
/* Tablets (max width) */
/* Small Desktop  (min width) */
/* Small Desktop (max width) */
/* Large Desktop (min width) - THIS IS THE DEFAULT STYLES */
/* Large Desktop Breakpoint where we need to apply global innerWrapper Padding */
/*Set maximum viewable area of the site */
/*@if(type-of($Columns) == list) {
    // loop through values and assign to columns
    @each $col in $Columns {
    }
}*/
.newGrid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.newGrid > .col {
  /*flex: 1 0 auto;*/
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  /*order: 0;*/
  min-width: 0;
  max-width: 100%;
  padding: 0 0.5rem;
  vertical-align: top;
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}

/* ### REM FALLBACK ### */
/*---------------   
--Mixin: overlay
----------------- */
/* Old Font Size Function
@mixin font-size($sizeValue, $line: 1.5){
  font-size: ($sizeValue) + px;
  line-height: ($line * $sizeValue) + px;
  font-size: ($sizeValue / 10) *.625 + rem;
  line-height: (($line * $sizeValue) / 10) *.625 + rem;
}
*/
/*
$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 24px;

@include fluid-type($min_width, $max_width, $min_font, $max_font);

*/
.accordionUL ul.level1 li.hasChildren ul li:before, ul.k-panelbar.k-header li.k-item ul.k-group li.k-item a.k-link:before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
}

.accordionUL ul.level1 li.hasChildren ul li:before, ul.k-panelbar.k-header li.k-item ul.k-group li.k-item a.k-link:before {
  font-weight: 700;
}

/* Force text to wrap with hyphen if to long (example long email) */
/*
    Placeholder usage:

    .className {
        @extend %placeHoldername;
    }
*/
nav ul.megaNav .k-animation-container > ul > li div.megaContainer .pageMenuImage .megaImage {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  position: absolute;
}

/* ### Navigation Menus ### */
.k-ie11 .k-menu .k-animation-container {
  left: 0px !important;
  top: 69px !important;
}
.k-ie11 .k-menu .k-animation-container .k-animation-container {
  left: 180px !important;
  top: 0px !important;
}

.pageWrapper {
  position: relative;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  left: 0;
}

/*#region MOBILE MENU*/
button.hamburger {
  display: none;
}

button.hamburger {
  top: 6px;
  right: 0;
  width: 60px;
  height: 54px;
  border: none;
  padding: 12px;
  position: absolute;
  z-index: 500;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background-color: rgba(255, 255, 255, 0.8);
}
button.hamburger:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.8);
}
button.hamburger:hover span {
  background-color: #0047BB;
}
button.hamburger span {
  width: 35px;
  height: 4px;
  display: block;
  background-color: #0047BB;
  margin: 0 0 8px 0;
}
button.hamburger div:nth-of-type(3) {
  width: 16px;
}
button.hamburger.open span {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: relative;
}
button.hamburger.open span:nth-child(1) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  top: 12px;
  left: -10px;
}
button.hamburger.open span:nth-child(2) {
  top: 0px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
button.hamburger.open span:nth-child(3) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  top: -12px;
  left: 10px;
}

.mobileHeader {
  position: absolute;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  left: -3000px;
}

.mobileHeader.withNavOpen {
  left: 0;
}

nav.mobile {
  position: fixed;
  padding-top: 120px;
  width: 100%;
  z-index: 100;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background-color: #003488;
  overflow-y: auto;
  height: 100%;
}
nav.mobile .k-treeview {
  padding: 0;
}
nav.mobile .k-treeview .k-in {
  white-space: normal;
}
nav.mobile .k-treeview .k-item {
  font-size: 2.5rem;
  border-bottom: solid 1px #00778B;
  position: relative;
}
nav.mobile .k-treeview .k-item:hover {
  background-color: #00778B;
}
nav.mobile .k-treeview .k-item.k-first {
  border-top: solid 1px #00778B;
}
nav.mobile .k-treeview .k-item .k-link {
  color: #fff;
}
nav.mobile .k-treeview .k-item .k-link.k-state-hover, nav.mobile .k-treeview .k-item .k-link.k-state-active, nav.mobile .k-treeview .k-item .k-link.k-state-selected, nav.mobile .k-treeview .k-item .k-link.k-state-focused {
  background: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  text-decoration: none;
}
nav.mobile .k-treeview .k-item .k-link.k-state-hover {
  padding-left: 25px;
}
nav.mobile .k-treeview .k-item .k-icon {
  position: absolute;
  right: 5%;
  top: 25px;
}
nav.mobile .k-treeview .k-item .k-icon:before {
  font-family: "Font Awesome 5 Pro";
  color: #fff;
  content: "";
  font-size: 1rem;
}
nav.mobile .k-treeview .k-item .k-group .k-item:hover {
  background-color: #003488;
}
nav.mobile .k-treeview .k-item .k-group .k-item .k-icon {
  top: 15px;
}
nav.mobile .k-treeview .k-item .k-group .k-item .k-link {
  font-size: 1.5rem;
}
nav.mobile .mobileNavWrapper .staticContent {
  color: #fff;
  padding: 0 2em;
}
nav.mobile .mobileNavWrapper .staticContent .sf-Long-text {
  color: #fff;
  font-size: 1.3em;
}
nav.mobile .mobileNavWrapper .staticContent .sf-Long-text a {
  color: #fff;
}

/*#endregion MOBILE MENU*/
/*#region MEGA(CFMega) MENU*/
nav ul.megaNav {
  visibility: hidden;
  border: none;
  background: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: -1px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  /* selected states (CFAdded selected or childSelected classes) */
  /* (The Mega Dropdown) */
}
nav ul.megaNav > .k-item {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  width: auto;
}
nav ul.megaNav.initialized {
  visibility: visible;
}
nav ul.megaNav .findASearchWrapper {
  margin: 0 auto 2em;
}
nav ul.megaNav .findASearchWrapper .k-widget.findASearchMenu {
  width: 20em !important;
  border: solid 2px #0047BB;
  font-size: 1.1rem;
  border-radius: 0;
}
nav ul.megaNav .findASearchWrapper .k-widget.findASearchMenu .k-select {
  background: transparent url(/CFImages/Icons/search-pipe.svg) no-repeat 0 50%;
}
nav ul.megaNav .findASearchWrapper .k-widget.findASearchMenu .k-select .k-icon:before {
  content: "";
}
nav ul.megaNav .rootMenuItem.k-item {
  border: none;
  background: none;
  position: relative;
  border-bottom: 2px solid transparent;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  /* hover state */
}
nav ul.megaNav .rootMenuItem.k-item .k-i-arrow-60-down:before {
  content: "";
  font-family: "Font Awesome 5 Pro";
  color: red;
  font-size: 50%;
  font-weight: 400;
}
nav ul.megaNav .rootMenuItem.k-item a.k-link {
  border: none;
  background: none;
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  text-transform: uppercase;
  padding: 0.5em 0.5em 1em 0.5em;
  padding: 2rem 0;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  /* The down icons */
}
nav ul.megaNav .rootMenuItem.k-item a.k-link .k-icon {
  margin: -7px -5px 0 5px;
}
nav ul.megaNav .rootMenuItem.k-item a.k-link .k-icon .k-icon:before {
  color: #000000;
}
nav ul.megaNav .rootMenuItem.k-item.k-state-hover {
  border-bottom: 2px solid #0047BB;
}
nav ul.megaNav .rootMenuItem.k-item.selected {
  border-bottom: 2px solid #0047BB;
}
nav ul.megaNav .rootMenuItem.k-item.childSelected {
  border-bottom: 2px solid #0047BB;
}
nav ul.megaNav .k-animation-container {
  width: -webkit-calc(100vw - 1em) !important;
  width: calc(100vw - 1em) !important;
  max-height: 750px !important;
  /*top: 65px !important;*/
  top: -webkit-calc(100% + 3px) !important;
  top: calc(100% + 3px) !important;
  border: none !important;
}
nav ul.megaNav .k-animation-container .k-item.k-state-default {
  white-space: normal !important;
}
nav ul.megaNav .k-animation-container > ul {
  width: 100% !important;
  border: none;
}
nav ul.megaNav .k-animation-container > ul > li {
  background-color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
nav ul.megaNav .k-animation-container > ul > li div.megaContainer {
  padding: 2em 6em;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -1rem;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
}
nav ul.megaNav .k-animation-container > ul > li div.megaContainer > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 1rem;
  vertical-align: top;
  /*width: calc((1 / 2) * 100% - 2rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 2rem);
      -ms-flex-preferred-size: calc((1 / 2) * 100% - 2rem);
          flex-basis: calc((1 / 2) * 100% - 2rem);
}
nav ul.megaNav .k-animation-container > ul > li div.megaContainer .megaLeft h2 {
  font-size: 1.8rem;
  margin-top: 0;
  margin-bottom: 0.4rem;
}
nav ul.megaNav .k-animation-container > ul > li div.megaContainer .megaRight {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
nav ul.megaNav .k-animation-container > ul > li div.megaContainer .megaRight .accordionUL > ul li {
  font-size: 0.8rem;
}
nav ul.megaNav .k-animation-container > ul > li div.megaContainer .megaRight.default .accordionUL > ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 3.5em 0 0 2em;
}
nav ul.megaNav .k-animation-container > ul > li div.megaContainer .megaRight.default .accordionUL > ul li {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 30%;
      -ms-flex: 0 0 30%;
          flex: 0 0 30%;
  margin-right: 1em;
}
nav ul.megaNav .k-animation-container > ul > li div.megaContainer .megaRight.default .accordionUL > ul li:last-child {
  border-bottom: none;
}
nav ul.megaNav .k-animation-container > ul > li div.megaContainer .pageMenuImage {
  width: 100%;
  height: 100px;
  position: relative;
  padding-top: 0.5em;
}
nav ul.megaNav .k-animation-container > ul > li div.megaContainer .pageMenuImage .megaImage {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
nav ul.megaNav .k-animation-container > ul > li div.megaContainer .panelBar {
  width: 90% !important;
  max-width: 500px !important;
}
nav ul.megaNav .k-animation-container > ul > li div.megaContainer .panelBar .k-menu .k-link {
  white-space: normal !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
nav ul.megaNav .k-animation-container > ul > li div.megaContainer .panelBar li {
  padding: 0;
}
nav ul.megaNav .k-animation-container > ul > li div.megaContainer.expertises .megaLeft {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
nav ul.megaNav .k-animation-container > ul > li div.megaContainer.expertises .megaRight {
  -webkit-box-flex: 2;
  -webkit-flex: 2;
      -ms-flex: 2;
          flex: 2;
}
nav ul.megaNav .k-animation-container > ul > li div.megaContainer.expertises .megaRight li {
  padding-right: 0.5rem;
}
nav ul.megaNav .k-animation-container > ul > li div.megaContainer.sectors .megaLeft {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
nav ul.megaNav .k-animation-container > ul > li div.megaContainer.sectors .megaRight {
  -webkit-box-flex: 2;
  -webkit-flex: 2;
      -ms-flex: 2;
          flex: 2;
}
nav ul.megaNav .k-animation-container > ul > li div.megaContainer.sectors .megaRight .accordionUL ul.level1 {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -2.5rem;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
nav ul.megaNav .k-animation-container > ul > li div.megaContainer.sectors .megaRight .accordionUL ul.level1 > li {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 2.5rem;
  vertical-align: top;
  /*width: calc((1 / 3) * 100% - 5rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 3) * 100% - 5rem);
      -ms-flex-preferred-size: calc((1 / 3) * 100% - 5rem);
          flex-basis: calc((1 / 3) * 100% - 5rem);
}
nav ul.megaNav .k-animation-container > ul > li div.megaContainer.sectors .megaRight .accordionUL ul.level1 li {
  margin-left: 0.5rem;
  margin-right: 0rem;
}
nav ul.megaNav .k-animation-container > ul > li div.megaContainer.sectors .megaRight .accordionUL ul.level1 li:last-child {
  border-bottom: none !important;
}

/*#endregion MEGA (CFMega) MENU*/
/*#region SIMPLE(CFDefault) MENU*/
nav ul.mainNav {
  display: none;
  z-index: 2;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-top: 0.5em;
  /* Sub elements */
  /* selected states (CFAdded selected or childSelected classes) */
  /*.k-group .k-item.childSelected {
      background-color: $greyLight;

      a.k-link.childSelected {
          color: #fff;
      }
  }*/
}
nav ul.mainNav.initialized {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
nav ul.mainNav.k-menu-horizontal {
  border: none;
  background: none;
}
nav ul.mainNav .rootMenuItem.k-item {
  border: none;
  background: none;
  text-align: left;
  /* hover state */
}
nav ul.mainNav .rootMenuItem.k-item a.k-link {
  border: none;
  background: none;
  font-size: 1em;
  padding: 1.5em 0.9em 0.5em 0.9em;
  color: #0047BB;
  /* The down icons */
}
nav ul.mainNav .rootMenuItem.k-item a.k-link .k-icon {
  margin: -2px -5px 0 5px;
}
nav ul.mainNav .rootMenuItem.k-item a.k-link .k-icon .k-icon:before {
  color: #fff;
}
nav ul.mainNav .rootMenuItem.k-item a.k-link.k-state-active {
  background-color: #0047BB;
  color: #fff;
}
nav ul.mainNav .rootMenuItem.k-item.k-state-hover a.k-link {
  color: #fff;
  background-color: #0047BB;
}
nav ul.mainNav ul.k-group {
  border: none;
  background-color: #0047BB;
  padding: 0.25em;
}
nav ul.mainNav ul.k-group.teammembers {
  -webkit-column-count: 2;
     -moz-column-count: 2;
          column-count: 2;
}
nav ul.mainNav ul.k-group .k-item {
  border: none;
  background: none;
  /* hover state */
}
nav ul.mainNav ul.k-group .k-item a.k-link {
  border: none;
  background: none;
  font-size: 1em;
  padding: 0.25em 1em;
  min-width: 180px;
  color: #fff;
  /* The down icons */
}
nav ul.mainNav ul.k-group .k-item a.k-link .k-icon {
  margin: -7px -5px 0 5px;
}
nav ul.mainNav ul.k-group .k-item a.k-link .k-icon:before {
  color: #fff;
}
nav ul.mainNav ul.k-group .k-item.k-state-hover {
  background-color: #003488;
}
nav ul.mainNav ul.k-group .k-group {
  margin-left: 5px;
  margin-top: -5px;
}
nav ul.mainNav .rootMenuItem.k-item.selected:after {
  content: "";
  height: 5px;
  background-color: #0047BB;
  width: 20px;
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
nav ul.mainNav .rootMenuItem.k-item.childSelected:after {
  content: "";
  height: 5px;
  background-color: #0047BB;
  width: 40px;
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
nav ul.mainNav .k-group .k-item.selected {
  background-color: #B1B1B1;
}
nav ul.mainNav .k-group .k-item.selected a.k-link.selected {
  color: #ffffff;
  background-color: #B1B1B1;
}

/*#endregion SIMPLE(CFDefault) MENU*/
/*#region TABSTRIP*/
.tabstrip {
  display: none;
}

.k-tabstrip-wrapper .tabstrip {
  display: block;
}

.k-tabstrip-wrapper .k-tabstrip {
  background: none;
  border: none;
}
.k-tabstrip-wrapper .k-tabstrip .k-tabstrip-items {
  margin: 0;
  text-transform: uppercase;
  background: none;
  border: none;
}
.k-tabstrip-wrapper .k-tabstrip .k-tabstrip-items .k-item {
  background: none;
  border: none;
  border-radius: 0;
}
.k-tabstrip-wrapper .k-tabstrip .k-tabstrip-items .k-item .k-loading {
  border: none;
}
.k-tabstrip-wrapper .k-tabstrip .k-tabstrip-items .k-item .k-link {
  font-size: 1em;
  font-weight: 700;
  background: none;
  border: none;
  color: #003488;
  text-transform: uppercase;
}
.k-tabstrip-wrapper .k-tabstrip .k-tabstrip-items .k-item.k-state-active .k-link {
  color: #0047BB;
}
.k-tabstrip-wrapper .k-tabstrip:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-tabstrip-wrapper .k-tabstrip .k-content {
  background-color: transparent;
  border: none;
}
.k-tabstrip-wrapper .k-tabstrip .k-content.k-state-active {
  background-color: transparent;
}

/*#endregion TABSTRIP*/
/*#region PanelBar */
.related.expertises.panelBar ul.level1,
.related.sectors.panelBar ul.level1 {
  display: none;
}
.related.expertises.panelBar ul.level1.k-panelbar,
.related.sectors.panelBar ul.level1.k-panelbar {
  display: block;
}

.mainPanelBar {
  display: none;
}
.mainPanelBar.k-panelbar {
  display: block;
}

ul.k-panelbar.k-header {
  margin: 1em 0;
  background: none !important;
  border: none;
}
ul.k-panelbar.k-header li.k-item {
  position: relative;
  padding: 1em 2em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-shadow: 0px 0px 0px 1px #0047BB;
          box-shadow: 0px 0px 0px 1px #0047BB;
  -webkit-transition: -webkit-box-shadow 0.2s linear;
  transition: -webkit-box-shadow 0.2s linear;
  transition: box-shadow 0.2s linear;
  transition: box-shadow 0.2s linear, -webkit-box-shadow 0.2s linear;
  margin-bottom: 1em;
  /*&.k-state-default, .k-state-focused, .k-state-hover, .k-state-selected, .k-state-active {

      span.k-icon.k-panelbar-collapse.k-i-arrow-n {
          &:before {
              font-family: $fontAwesome;
              display: flex;
              justify-content: center;
              font-size: 50%;
              padding-top: 5px;
              color: $secondaryColour;
              content: $arrowUpIcon !important;
          }
      }
  }
  // Open Dropdown
  &.k-state-active {
      &:before {
          @extend %hugParent;
          opacity: 0.1;
          background-color: $opacityBlue;
          content: " ";
      }

      a.k-link {
      }
  }*/
}
ul.k-panelbar.k-header li.k-item:before {
  content: "";
  width: 4px;
  height: 30%;
  background-color: #fff;
  top: 5px;
  position: absolute;
  left: -4px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
ul.k-panelbar.k-header li.k-item:after {
  content: "";
  width: 4px;
  height: 30%;
  background-color: #fff;
  bottom: 5px;
  position: absolute;
  right: -4px;
}
ul.k-panelbar.k-header li.k-item:hover {
  -webkit-box-shadow: 0px 0px 0px 3px #0047BB;
          box-shadow: 0px 0px 0px 3px #0047BB;
}
ul.k-panelbar.k-header li.k-item + .k-item {
  border-top-width: 0;
}
ul.k-panelbar.k-header li.k-item a.k-link {
  line-height: 1.25em;
  background: none !important;
  color: #000000;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
}
ul.k-panelbar.k-header li.k-item a.k-link:hover, ul.k-panelbar.k-header li.k-item a.k-link:focus {
  color: #0047BB;
}
ul.k-panelbar.k-header li.k-item.k-last {
  border-bottom: none;
}
ul.k-panelbar.k-header li.k-item span.k-icon.k-panelbar-expand.k-i-arrow-s:before {
  font-family: "Font Awesome 5 Pro";
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 50%;
  padding-top: 5px;
  color: #0047BB;
  content: "" !important;
}
ul.k-panelbar.k-header li.k-item ul.k-group {
  background: none !important;
  border: none !important;
  padding-left: 1em;
}
ul.k-panelbar.k-header li.k-item ul.k-group li.k-item {
  padding: 0.25em 0.25em;
  border-bottom: none;
}
ul.k-panelbar.k-header li.k-item ul.k-group li.k-item a.k-link {
  position: relative;
  background: none !important;
  color: #000000;
  font-weight: normal;
  text-transform: none;
}
ul.k-panelbar.k-header li.k-item ul.k-group li.k-item a.k-link:before {
  position: absolute;
  top: 0.2em;
  left: 0.25em;
  color: #0047BB;
  content: "";
  font-size: 0.3em;
}
ul.k-panelbar.k-header li.k-item ul.k-group li.k-item a.k-link:hover, ul.k-panelbar.k-header li.k-item ul.k-group li.k-item a.k-link:focus {
  color: #0047BB;
}

/*# end region PanelBar */
/*#region ULListAccordian */
.accordionUL {
  position: relative;
}
.accordionUL ul.level1 li {
  text-transform: uppercase;
  position: relative;
}
.accordionUL ul.level1 li.hasChildren {
  padding-right: 25px;
}
.accordionUL ul.level1 li.hasChildren span.toggle {
  position: absolute;
  right: 0;
  color: #0047BB;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.accordionUL ul.level1 li.hasChildren span.toggle:after {
  font-family: "Font Awesome 5 Pro";
  content: "";
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 50%;
  padding-top: 5px;
}
.accordionUL ul.level1 li.hasChildren ul {
  display: none;
  padding-left: 2em;
}
.accordionUL ul.level1 li.hasChildren ul li {
  text-transform: none;
  position: relative;
  margin-left: 1em;
}
.accordionUL ul.level1 li.hasChildren ul li:before {
  position: absolute;
  top: 1.2em;
  left: -1.5em;
  color: #0047BB;
  content: "";
  font-size: 0.3em;
}
.accordionUL ul.level1 li.hasChildren ul li ul {
  display: block;
}
.accordionUL ul.level1 li.hasChildren ul li.hasChildren span.toggle {
  display: none;
  padding-left: 0;
}
.accordionUL ul.level1 li.hasChildren.active span.toggle:after {
  content: "";
}